
<div class="custom-table-list">
  <template v-if="columnsFiltered.length">
    <div class="header">
      <span
        class="cell"
        :style="[columnCellStyle(column)]"
        v-for="column in columnsFiltered"
        :key="column.id"
      >
        <span>{{ column.label }}</span>
      </span>
    </div>
    <div class="body">
      <div
        class="row"
        :class="dynRowClass(row)"
        v-for="(row, rowIndex) in rows"
        :key="row.id"
        @click="rowCardSelected($event, row)"
      >
        <div
          class="cell"
          :class="{ slot: column.slots && Object.keys(column.slots).length }"
          :style="[columnCellStyle(column)]"
          v-for="column in columnsFiltered"
          :key="column.id"
        >
          <table-cell
            :cell-data="row[column.name]"
            :row="row"
            :column="column"
          >
            <!-- Remove btn -->
            <template 
              v-if="
                column.slots &&
                column.slots.remove &&
                column.canShowCellFunc &&
                column.canShowCellFunc(row)
              ">
              <v-popover :ref="'popover-remove-' + rowIndex">
                <button
                  class="mini close-btn"
                  @click.stop="showPopoverRemove(rowIndex)"
                >
                  <i class="fa fa-times"></i>
                </button>
                <template slot="popover">
                  <div class="popover-action-slot">
                    <span>{{ $t('app.confirmProceed') }}?</span>
                    <div class="action">
                      <button
                        class="acc"
                        @click.stop="remove($event, row, rowIndex)"
                        v-close-popover
                      >
                        <span>
                          <span>{{ $t('app.confirm') }}</span>
                        </span>
                      </button>
                      <button
                        @click.stop.prevent
                        v-close-popover
                      >
                        <span>
                          <span>{{ $t('app.cancel') }}</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </template>
              </v-popover>
            </template>
            <!-- Classes btn -->
            <template v-if="column.slots && column.slots.classes">
              <router-link
                :to="classTableListRoute(clientData, row)"
                class="classes-btn"
                :title="$i18n.tc('app.class.title', 2)"
              >
                <i class="fa fa-graduation-cap"></i>
                <span>{{ $tc('app.class.title', 2) }}</span>
              </router-link>
            </template>
            <!-- Membership btn -->
            <template v-if="column.slots && column.slots.memberships">
              <router-link
                :to="{
                  name: 'MembershipTableUser',
                  params: { client_id: clientData.id, user_id: row.id },
                }"
                class="classes-btn"
                :title="$tc('app.membership.title', 2)"
              >
                <button class="mini acc alt">
                  <span>
                    <i class="fa fa-id-card"></i>
                    <span>{{ $tc('app.membership.title', 2) }}</span>
                  </span>
                </button>
              </router-link>
            </template>
            <!-- Edit Group -->
            <template v-if="column.slots && column.slots.editGroup">
              <router-link
                :to="{
                  name: 'GroupEdit',
                  params: { client_id: clientData.id, group_id: row.id },
                }"
                class="group-btn"
              >
                <i class="fa fa-pen"></i>
                <span>{{ $t('app.editGroup') }}</span>
              </router-link>
            </template>
            <!-- Edit Group -->
            <template v-if="column.slots && column.slots.editClient">
              <router-link
                :to="{ name: 'ClientEdit', params: { client_id: row.id } }"
                class="group-btn"
              >
                <i class="fa fa-pen"></i>
                <span>{{ $t('app.editClient') }}</span>
              </router-link>
            </template>
            <!-- Active Toggle Switch -->
            <template v-if="column.slots && column.slots.activate">
              <v-popover :ref="'popover-status-' + rowIndex">
                <checkbox-input
                  class="tooltip-target b3"
                  :checked="row._isActiveToggle === 1"
                  :options="mergedOptions.activationOptions"
                  @change="showPopoverActive(rowIndex)"
                  @click.native.stop
                ></checkbox-input>
                <template slot="popover">
                  <div class="popover-action-slot">
                    <span>{{ $t('app.confirmProceed') }}?</span>
                    <div class="action">
                      <button
                        class="acc"
                        @click.stop="toggleActivation($event, row, rowIndex)"
                        v-close-popover
                      >
                        <span>
                          <span>{{ $t('app.confirm') }}</span>
                        </span>
                      </button>
                      <button
                        @click.stop.prevent
                        v-close-popover
                      >
                        <span>
                          <span>{{ $t('app.cancel') }}</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </template>
              </v-popover>
            </template>
          </table-cell>
        </div>
      </div>
    </div>
  </template>
  <div
    class="no-data"
    v-if="columnsFiltered.length === 0"
  >
    <span>{{ $t('app.table.noData') }}</span>
  </div>
</div>
