import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export default {
  // PARSE ONLY
  parseErrorMsg (err) {
    if (err?.response?.data?.message) {
      return err.response.data.message;
    } else if (err.message) {
      return err.message;
    } else if (err) {
      return err;
    }
    return 'Unknown Error';
  },
  parseErrorArr (err) {
    if (err?.response?.data?.errors) {
      return err.response.data.errors;
    }
    return [{ general: 'Unknown Error' }];
  },

  // PARSE and PRINT
  parsePrintErrorMsg (err, isShowToast = true) {
    const errMsg = this.parseErrorMsg(err);
    console.log('[--⚠️--]', errMsg);

    if (isShowToast) {
      Toastify({
        text: errMsg,
        duration: 3000,
        // destination: "",
        newWindow: true,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'right', // `left`, `center` or `right`
        // backgroundColor: "linear-gradient(45deg, rgb(241 155 155), rgb(225 129 130))",
        className: 'toastify-error',
        stopOnFocus: true, // Prevents dismissing of toast on hover
        onClick: function () { }, // Callback after click
      }).showToast();
    }
  },
};
