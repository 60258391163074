
<div id="client-add">
  <div class="form" @input="inputCapture">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{$tc('app.client.title',1)}}</span>
    </div>
    <div class="input-group">
      <input
        class="alt"
        v-model.trim="form.cln_name"
        :placeholder="placeholderTranslations.name"
        :title="placeholderTranslations.name"
        type="text"
        required
      />
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="countriesProps"
        :handleEvents="countriesEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="clientTypesProps"
        :handleEvents="clientTypesEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <div class="input-group">
      <textarea
        class="alt max-w"
        v-model.trim="form.cln_description"
        :placeholder="placeholderTranslations.clientDescription"
        :title="placeholderTranslations.clientDescription"
        type="text"
      />
    </div>
    <div class="text-mini">* {{$t('app.requiredFields')}}</div>
    <button class="acc long" :disabled="isApiLoading" @click="manageClient">
      <span :class="{'progress': isApiLoading}">
        <i class="fa fa-save"></i>
        <span>{{$t('app.save')}}</span>
      </span>
    </button>
  </div>
</div>
