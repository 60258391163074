export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      defaultOptions: {
        cellStyle: {
          width: '100px',
        },
        rowClasses: {
          selectable: true,
        },
        activationOptions: {
          isCheckbox: false,
          value: false,
        },
      },
    };
  },
  computed: {
    columnsFiltered () {
      if (!this.columns.length) {
        return this.columnsDefaultCols;
      }
      if (this.isMobileView) {
        // Only for mobile view
        return this.columns.filter(column => !column.hidden && !column.hiddenMobile && this.checkHasPermission(column.permissions));
      } else {
        return this.columns.filter(column => {
          if (Object.prototype.hasOwnProperty.call(column, 'canShowCellFunc')) {
            // Check against all rows [Special case]
            const hasAllEmptyCells = this.rows.every(row => !column.canShowCellFunc(row));
            if (hasAllEmptyCells) {
              return false;
            }
          }

          return !column.hidden && !column.onlyMobile && this.checkHasPermission(column.permissions);
        });
      }
    },
    columnsDefaultCols () {
      if (this.rows.length) {
        return this.generateDefaultColumn(this.rows[0]);
      }
      return [];
    },
    mergedOptions () {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
  methods: {
    generateDefaultColumn (obj) {
      return Object.keys(obj).map(key => {
        return {
          name: key,
          label: key,
        };
      });
    },
    columnCellStyle (column, isMob) {
      let configStyle = {};
      if (column.style) {
        configStyle = { ...column.style };
      } else if (column.name === 'remove') {
        configStyle = { width: '40px' };
      } else {
        configStyle = { ...this.mergedOptions.cellStyle } || {};
      }

      // Mobile workaround
      if (isMob) {
        delete configStyle.width;
      } else {
        // Desktop - Setup (min) width
        if (configStyle.width && !configStyle.minWidth) {
          configStyle.minWidth = configStyle.width;
        }
      }
      return configStyle;
    },
    dynRowClass (row) {
      const defaultClasses = { ...this.mergedOptions.rowClasses };
      if (row._isActiveToggle === 0) {
        // Custom class setup
        defaultClasses['disabled-low'] = true;
      }
      return defaultClasses;
    },
    toggleActivation (evt, row, index) {
      const emulateEvt = { checked: !row._isActiveToggle };

      this.$emit('toggle-activation', {
        evt: emulateEvt,
        row,
      });
    },
    remove (evt, row, index) {
      this.$emit('click-remove', row);
    },
    showPopoverRemove (index) {
      const elRef = this.$refs['popover-remove-' + index]?.[0];
      elRef.show();
    },
    showPopoverActive (index) {
      const elRef = this.$refs['popover-status-' + index]?.[0];
      elRef.show();
    },
    rowCardSelected (evt, row) {
      const emitFunc = () => {
        this.$emit('row-select', row);
      };

      if (this.options?.rowClasses?.selectable !== false) {
        emitFunc();
      } else {
        console.log('[Blocked] entry selection');
      }
    },
    classTableListRoute (clientData, row) {
      return {
        name: 'ClassTableListAll',
        params: {
          client_id: clientData.id,
          group_id: row.id,
        },
      }
    },
  },
};
