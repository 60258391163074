function getStoragePermissions (permKey = '', userState) {
  try {
    // console.log('userstate', userState);
    const userData = userState || JSON.parse(localStorage.user);
    const userPerms = userData?.user_permissions;
    const clientPerms = userData?.user_client_permissions;
    const allPerms = (userPerms || []).concat(clientPerms || []);
    if (!allPerms?.length) {
      console.warn('[❌] - Invalid user perms', allPerms, permKey);
    }
    return allPerms || [];
  } catch (err) {
    console.log(err.message);
  }
  return [];
}

export default {
  canAccessPermission (permKey = '', userState) {
    if (permKey.includes('|')) {
      // Needs to have one of
      const perms = permKey.split('|');
      return perms.some(perm => this.userHasPermission(perm, userState));
    } else if (permKey.includes('&')) {
      // Needs to have all
      const perms = permKey.split('&');
      return perms.every(perm => this.userHasPermission(perm, userState));
    } else if (!permKey) {
      return true;
    }
    return this.userHasPermission(permKey, userState);
  },
  userHasPermission (permKey, userState) {
    // Internal use!
    const appPerms = getStoragePermissions(permKey, userState);
    const isPermOk = appPerms.includes(permKey);
    if (!isPermOk) {
      console.log('[❌] - No permission', permKey);
    }
    return isPermOk;
  },
};
