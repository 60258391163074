<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'App',
  metaInfo () {
    const locale = this.$store.state.appLangSelected.lang;
    const name = 'Inpaso';
    let keyword = 'dance, dance school, dance school program';
    let desc = 'Dance School Program';
    if (locale === 'sr') {
      desc = 'Program za plesnu školu';
      keyword = 'ples, škola plesa, program škole plesa';
    }

    return {
      title: name,
      meta: [
        { name: 'name', content: name },
        { name: 'og:title', content: name },
        { name: 'description', content: desc },
        { name: 'og:description', content: desc },
        { name: 'og:type', content: 'website' },
        { name: 'keywords', content: keyword },
        { name: 'og:keywords', content: 'website' },

      ],
      htmlAttrs: {
        lang: locale,
        'data-touch': this.isTouchDevice,
      },
      bodyAttrs: {
        class: this.metaBodyClass,
      },
    };
  },
  computed: {
    metaBodyClass () {
      if (this.isMobileView) {
        return ['mobile'];
      }
      return [];
    },
    isTouchDevice () {
      return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    },
  },
  mounted () {
    this.setupMobileView();
    window.addEventListener('resize', this.setupMobileView);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setupMobileView);
  },
  methods: {
    ...mapActions({
      setMobileView: 'setMobileView',
    }),
    setupMobileView () {
      // outerWidth fails onload [Chromium]
      // ontouchstart - document.documentElement - sometimes fails
      const mobileCheckRegex = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isPcOuterWidth = window.outerWidth ? window.outerWidth < 768 : false;
      const isMobWidth = window.innerWidth < 768;
      const isMobile = isMobWidth || isPcOuterWidth || mobileCheckRegex();
      this.setMobileView(isMobile);
    },
  },
};
</script>

<style lang='scss'>
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
  scrollbar-width: auto;
  scrollbar-color: lighten($color: $accent-color, $amount: 10)
    rgb(240, 240, 240);
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

#app {
  min-height: 100%;
  // min-height: 100vh;
  // min-width: 99vw;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background: white;
}

::-webkit-scrollbar-thumb {
  background: lighten($color: $accent-color, $amount: 10);
  border-radius: 4px;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
</style>
