<template>
  <div class="datepicker-wrap">
    <datetime
      v-bind="$attrs"
      :input-class="getInputClasses"
      :value="parsedValue"
      :placeholder="placeholder"
      :title="placeholder"
      :phrases="phrasesTransl"
      @input="setServerDateFormat"
    />
  </div>
</template>

<script>
import { Settings, DateTime } from 'luxon';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {},
    placeholder: {
      type: String,
      default: '',
    },
    inputClass: String,
  },
  computed: {
    parsedValue () {
      // Used so the datetime comp parsed values properly
      return `${this.value}T00:00:00.000Z`;
    },
    getInputClasses () {
      return `alt ${this.inputClass}`;
    },
    phrasesTransl () {
      return {
        ok: 'OK',
        cancel: this.$i18n.t('app.cancel'),
      };
    },
    currentLocale () {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLocale (val) {
      this.setDefaultLocale();
    },
  },
  mounted () {
    this.setDefaultLocale();
  },
  methods: {
    setServerDateFormat (evtStr) {
      const formatDate = (inputDate) => {
        // Converts ugly ISO to db ISO format (.., true, true)
        return this.convertDateFromIsoToLocalAndReverse(inputDate, true, true);
      };

      const inputDate = evtStr;
      if (inputDate) {
        this.$emit('change', formatDate(inputDate));
      } else {
        // This seems to not work
        // console.log('[🐛] Date picker initial load event', this.value);
      }
    },
    setDefaultLocale () {
      let currLocale = this.$i18n.locale;
      if (currLocale === 'sr') {
        // For latin
        currLocale = 'sr-latn';
      }
      Settings.defaultLocale = currLocale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
