<template>
  <div id="class-table-list">
    <div class="top-head">
      <div class="left">
        <router-link
          :to="{ name: 'GroupTableListAll', params: { ...$route.params } }"
          v-if="checkHasPermission('view-groups') && canGoBack"
          class="btn"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t("app.back") }}</span>
          </span>
        </router-link>
        <router-link
          :to="getDynamicPreviousRoute"
          class="btn"
          v-else-if="canGoBack"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t("app.back") }}</span>
          </span>
        </router-link>
        <span>{{ $tc("app.class.title", 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
        <span
          :title="$i18n.tc('app.group.title', 1)"
          v-if="checkHasPermission('view-groups') && canGoBack && groupDataName"
          >- ({{ groupDataName }})</span
        >
      </div>
      <div class="center">
        <!-- <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="activeStatusSelected"
        /> -->
      </div>
      <div class="right">
        <router-link
          :to="{ name: 'ClassAdd' }"
          v-if="checkHasPermission('create-classes')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t("app.class.addNew") }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="classesData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
// import TableFilters from '@/components/Shared/table-filters/TableFilters.vue'
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'ClassTableList',
  components: {
    // TableFilters,
    TableWrapper,
  },
  props: {
    group_id: {
      type: [String, Number],
      required: true,
    },
    // classesData: {
    //   type: Array,
    //   default: () => ([])
    // },
  },
  data () {
    return {
      classesData: [],
      groupData: {},
      tableShowFunc: false,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        cls_id: 'id',
        cls_name: 'name',
        cls_description: 'description',
        cls_date: 'date',
        cls_phone: 'phone',
      },
    };
  },
  computed: {
    canGoBack () {
      // return this.$route.name !== 'ClassTableListAll'
      return true;
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('auth.name'),
          hidden: true,
        },
        {
          name: 'date',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.date'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '500px',
          },
        }
        // {
        //   name: 'users_count',
        //   label: this.$i18n.t('app.usersCount')
        // }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-classes'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        activeStatus: false,
        // activeStatus: this.checkHasPermission('activate-deactivate-classes')
      };
    },
    groupDataName () {
      return this.groupData?.name || this.groupData?.grp_name || "";
    }
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchClassesList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchClassesList();
    if (this.$route.params.group_id) {
      this.fetchGroupData(this.$route.params.group_id);
    }
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getClasses: 'getClasses',
      getOneGroup: 'getOneGroup',
    }),
    async fetchClassesList () {
      const params = {
        grp_id: this.group_id,
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getClasses(params);
        const tempData = res.data.data;
        this.classesData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchGroupData (id) {
      try {
        const res = await this.getOneGroup({ id });
        this.groupData = res.data.data?.[0]
      } catch (err) {
        console.warn(err.message);
      }
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchClassesList();
    },
    async rowSelect (rowEvt) {
      const params = {
        class_id: rowEvt.id,
      };

      this.$router.push({
        name: 'ClassEdit',
        params: {
          class_id: rowEvt.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#class-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
