<template>
  <div id="profile-view">
    <div class="form">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{ $t('app.profile') }}</span>
      </div>
      <div class="form-el" @keydown.enter="manageUser">
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.name"
            :title="placeholderTranslations.name"
            v-model="form.usr_first_name"
            type="text"
          />
        </div>
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.lastname"
            :title="placeholderTranslations.lastname"
            v-model="form.usr_last_name"
            type="text"
          />
        </div>
        <!-- <div class="input-group">
        <multiselect-view
          :handleProps="genderProps"
          :handleEvents="genderEvents"
          :slot-text="multiSlotsMultiselect"
        />
        </div>-->
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.phone"
            :title="placeholderTranslations.phone"
            v-model="form.usr_phone"
            type="text"
          />
        </div>
        <div class="input-group">
          <datepicker-wrapper
            v-model="form.usr_birth_date"
            :placeholder="placeholderTranslations.birthDate"
            :max-datetime="getTomorrowDate"
          />
        </div>
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.email"
            :title="placeholderTranslations.email"
            v-model="form.usr_email"
            type="email"
          />
        </div>
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.oldPassword"
            :title="placeholderTranslations.oldPassword"
            v-model="form.old_password"
            type="password"
          />
        </div>
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.newPassword"
            :title="placeholderTranslations.newPassword"
            v-model="form.new_password"
            autocomplete="new-password"
            type="password"
          />
        </div>
        <div class="input-group">
          <input
            class="alt"
            :placeholder="placeholderTranslations.confirmPassword"
            :title="placeholderTranslations.confirmPassword"
            v-model="form.confirm_password"
            type="password"
          />
        </div>
      </div>
      <button class="acc" :disabled="isApiLoading" @click="manageUser">
        <span :class="{ progress: isApiLoading }">
          <i class="fa fa-save"></i>
          <span>{{ $t('app.save') }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions, mapState } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      formBaseData: {}, // Not used currently
      form: {},
      isApiLoading: false,
    };
  },
  computed: {
    // genderOptions () {
    //   return [
    //     {
    //       usr_gender: 1,
    //       name: this.$i18n.t('app.male'),
    //     },
    //     {
    //       usr_gender: 2,
    //       name: this.$i18n.t('app.female'),
    //     },
    //     {
    //       usr_gender: 3,
    //       name: this.$i18n.t('app.other'),
    //     }
    //   ]
    // },
    // genderProps () {
    //   return {
    //     // __isFixedDrop: true,
    //     options: this.genderOptions,
    //     placeholder: this.placeholderTranslations.gender,
    //     title: this.placeholderTranslations.gender,
    //     value: this.genderOptions.find(item => item.usr_gender === this.form.usr_gender),
    //     searchable: true,
    //     label: 'name',
    //   }
    // },
    // genderEvents () {
    //   return {
    //     select: (evt) => this.$set(this.form, 'usr_gender', evt.usr_gender),
    //     remove: () => this.$set(this.form, 'usr_gender', null),
    //   }
    // },
  },
  mounted () {
    this.fetchProfile();
  },
  methods: {
    ...mapActions({
      getProfile: 'getProfile',
      patchProfile: 'patchProfile',
    }),
    async manageUser () {
      this.isApiLoading = true;
      await this.editForm();
      this.isApiLoading = false;
    },
    async fetchProfile () {
      const params = {};
      try {
        const res = await this.getProfile(params);
        this.setupUserDataToStorage({ user: res.data.data });
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      } finally {
        this.form = { ...this.userData };
      }
    },
    async editForm () {
      const params = this.omitInvalidVals(this.form);
      try {
        const res = await this.patchProfile(params);
        this.setupUserDataToStorage({ user: res.data.data });
        this.showSuccessMsg(this.$i18n.t('app.success'));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    omitInvalidVals (formObj) {
      const fd = { ...formObj };
      if (!fd.old_password) {
        delete fd.old_password;
        delete fd.new_password;
        delete fd.confirm_password;
      }
      if (!fd.usr_birth_date) {
        delete fd.usr_birth_date;
      }

      return fd;
    },
  },
};
</script>

<style lang="scss" scoped>
#profile-view {
  @include sub-page;

  .form {
    @include forms;
  }
}
</style>
