
<div class="datepicker-wrap">
  <datetime
    v-bind="$attrs"
    :input-class="getInputClasses"
    :value="parsedValue"
    :placeholder="placeholder"
    :title="placeholder"
    :phrases="phrasesTransl"
    @input="setServerDateFormat"
  />
</div>
