
<div id="membership-types-add">
  <div class="form" @input="inputCapture">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{$tc('app.membershipType.title',1)}}</span>
    </div>
    <div class="input-group">
      <input
        class="alt"
        v-model="form.mmt_name"
        :placeholder="placeholderTranslations.name"
        :title="placeholderTranslations.name"
        type="text"
        required
      />
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="currenciesProps"
        :handleEvents="currenciesEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <div class="input-group">
      <input
        class="alt price"
        v-model="form.mmt_amount"
        :placeholder="placeholderTranslations.price"
        :title="placeholderTranslations.price"
        min="0"
        type="number"
        required
      />
    </div>
    <div class="input-group">
      <textarea
        class="alt max-w"
        v-model="form.mmt_description"
        :placeholder="placeholderTranslations.description"
        :title="placeholderTranslations.description"
        type="text"
      />
    </div>
    <div class="text-mini">* {{$t('app.requiredFields')}}</div>
    <button class="acc long" :disabled="isApiLoading" @click="manageMembership">
      <span :class="{'progress': isApiLoading}">
        <i class="fa fa-save"></i>
        <span>{{$t('app.save')}}</span>
      </span>
    </button>
  </div>
</div>
