
<div id="profile-view">
  <div class="form">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{ $t('app.profile') }}</span>
    </div>
    <div class="form-el" @keydown.enter="manageUser">
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.name"
          :title="placeholderTranslations.name"
          v-model="form.usr_first_name"
          type="text"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.lastname"
          :title="placeholderTranslations.lastname"
          v-model="form.usr_last_name"
          type="text"
        />
      </div>
      <!-- <div class="input-group">
      <multiselect-view
        :handleProps="genderProps"
        :handleEvents="genderEvents"
        :slot-text="multiSlotsMultiselect"
      />
      </div>-->
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.phone"
          :title="placeholderTranslations.phone"
          v-model="form.usr_phone"
          type="text"
        />
      </div>
      <div class="input-group">
        <datepicker-wrapper
          v-model="form.usr_birth_date"
          :placeholder="placeholderTranslations.birthDate"
          :max-datetime="getTomorrowDate"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.email"
          :title="placeholderTranslations.email"
          v-model="form.usr_email"
          type="email"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.oldPassword"
          :title="placeholderTranslations.oldPassword"
          v-model="form.old_password"
          type="password"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.newPassword"
          :title="placeholderTranslations.newPassword"
          v-model="form.new_password"
          autocomplete="new-password"
          type="password"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          :placeholder="placeholderTranslations.confirmPassword"
          :title="placeholderTranslations.confirmPassword"
          v-model="form.confirm_password"
          type="password"
        />
      </div>
    </div>
    <button class="acc" :disabled="isApiLoading" @click="manageUser">
      <span :class="{ progress: isApiLoading }">
        <i class="fa fa-save"></i>
        <span>{{ $t('app.save') }}</span>
      </span>
    </button>
  </div>
</div>
