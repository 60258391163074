import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import { Datetime } from 'vue-datetime';
import MultiselectView from '@/components/Shared/multiselect/MultiSelectView.vue';
import PaginationWrap from '@/components/Shared/pagination/PaginationWrap.vue';
import PaginationView from '@/components/Shared/pagination/PaginationView.vue';
import CheckboxInput from '@/components/Shared/checkbox/CheckboxInput.vue';
import SpinnerView from '@/components/Shared/spinner/SpinnerView.vue';
import DatepickerWrapper from '@/components/Shared/datepicker/DatepickerWrapper.vue';
import VueMeta from 'vue-meta';

import 'vue-datetime/dist/vue-datetime.css';
import globalMixin from './assets/js/globalMixin.js';
import VTooltip from 'v-tooltip';

Vue.component('datetime', Datetime);
Vue.component('datepicker-wrapper', DatepickerWrapper); // Used for datetime import
Vue.component('multiselect-view', MultiselectView);
Vue.component('pagination-view', PaginationView);
Vue.component('pagination-wrap', PaginationWrap); // Used for pagination comp
Vue.component('checkbox-input', CheckboxInput);
Vue.component('spinner-view', SpinnerView);
Vue.mixin(globalMixin);

Vue.use(VTooltip);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
