
<div id="location-add">
  <div class="form">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{ $tc('app.location.title', 1) }}</span>
    </div>
    <div class="input-group">
      <input
        class="alt"
        v-model="form.lct_name"
        :placeholder="placeholderTranslations.locationName"
        :title="placeholderTranslations.locationName"
        type="text"
        required
        :minlength="2"
        :maxlength="30"
      />
    </div>
    <div class="input-group">
      <input
        class="alt"
        v-model="form.lct_address"
        :placeholder="placeholderTranslations.address"
        :title="placeholderTranslations.address"
        type="text"
        required
        :minlength="2"
        :maxlength="30"
      />
    </div>
    <!-- <div class="input-group">
      <multiselect-view
        :handleProps="citiesProps"
        :handleEvents="citiesEvents"
        :slot-text="multiSlotsMultiselect"
        :title="placeholderTranslations.location"
      />
    </div>-->
    <div class="text-mini">* {{ $t('app.requiredFields') }}</div>
    <button class="acc" :disabled="isApiLoading" @click="manageLocation">
      <span :class="{ progress: isApiLoading }">
        <i class="fa fa-save"></i>
        <span>{{ $t('app.save') }}</span>
      </span>
    </button>
  </div>
</div>
