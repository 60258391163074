export default {
    props: {
        checked: {
            required: false,
        },
        options: {
            type: Object,
            default: () => {
                return {};
            },
            required: false,
        },
    },
    computed: {
        isDirectChecked () {
            if (this.checked || this.checked !== undefined) {
                return true;
            }
            return false;
        },
        getChecked () {
            if (this.isDirectChecked) {
                return this.checked;
            }
            if (this.options && this.options.checked) {
                return this.options.checked;
            }
            return null;
        },
        getLeftText () {
            return this.options.text;
        },
        getRightText () {
            return this.options.rightText;
        },
        tabIndexBasedOnDisabled () {
            if (this.options.disabled) {
                return -1;
            }
            return 0;
        },
    },
    methods: {
        changeRefInput () {
            this.$refs.input.click();
        },
    },
};
