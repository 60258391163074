<template lang='pug'>
.multiselect-view(:class='multiClasses')
  multiselect(
    v-on='dynEvents',
    v-bind='dynProps',
    @open='vmsOpenEvt',
    @select='selectEventHandle',
    @input='inputEventHandle',
    ref='vms'
  )
    template(slot='singleLabel', slot-scope='props')
      slot(name='option-single', :props='props')
        span {{ getSlotTextSingle(props) }}
    template(slot='option', slot-scope='props')
      slot(name='option-pre', :props='props')
        span {{ getSlotTextPre(props) }}
    span(slot='noOptions', v-if='slotText.noOptions !== null') {{ slotText.noOptions }}
    span(slot='noResult', v-if='slotText.noResult !== null') {{ slotText.noResult }}
</template>

<script>
// Additional prop options: || (d) - default
// Wrapper is absolute by default
// __isStatic: dropdown wrapper will be static (css)
// __isFixedDrop: dropdown wrapper will be fixed (css)
// __noCaret: dropdown caret wont be shown if > true | (d) false
// __noBorder: disabled dropdown border
// __autofocus: dropdown will be focused on mounted (display) > true | (d) false
// __noScroll: dropdown selected element wont be scrolled to > true | (d) false
import Multiselect from 'vue-multiselect';
export default {
  components: {
    Multiselect,
  },
  props: {
    handleEvents: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    handleProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    value: null,
    slotText: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    invalidTextVal () {
      return '\u200B';
    },
    dynEvents () {
      return this.handleEvents;
    },
    dynProps () {
      return this.handleProps;
    },
    hasElementOptions () {
      return Boolean(this.dynProps.options?.length);
    },
    isStaticDrop () {
      return Boolean(this.dynProps.__isStatic);
    },
    multiClasses () {
      return {
        'has-options': this.hasElementOptions,
        'static-drop': this.dynProps.__isStaticDrop,
        'fixed-drop': this.dynProps.__isFixedDrop,
        'no-caret': this.dynProps.__noCaret,
        'no-border': this.dynProps.__noBorder,
      };
    },
  },
  mounted () {
    if (this.dynProps.__autofocus) {
      const inputSearchEl = document.querySelector('.multiselect-view input');
      if (inputSearchEl) {
        // Autofocus input automatically
        inputSearchEl.focus();
      }
    }

    document.addEventListener('keyup', this.checkKeyUpSelect);
  },
  destroyed () {
    document.removeEventListener('keyup', this.checkKeyUpSelect);
  },
  methods: {
    checkKeyUpSelect (evt) {
      if (evt.code === 'Escape' || (evt.code === 'Enter' && !this.searchVal)) {
        // So that the vue multiselect doesn't crash (on blur)
        this.$refs.vms.$data.isOpen = false;
        this.$emit('key-close');
      }
    },
    vmsOpenEvt () {
      this.$nextTick(() => {
        // Wait for comp to render
        if (!this.dynProps.__noScroll) {
          const selEl = this.$refs.vms.$el.querySelector('.multiselect__option--selected');
          if (selEl) {
            selEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      });

      if (this.dynProps.__isFixedDrop) {
        this.getFixedPositionCoordinates();
      }
    },
    getFixedPositionCoordinates () {
      const el = this.$refs.vms.$el;
      const dropMenu = el.querySelector('.multiselect__content-wrapper');
      if (dropMenu) {
        const viewportOffset = el.getBoundingClientRect();
        const top = viewportOffset.top + el.offsetHeight;
        const left = viewportOffset.left;
        dropMenu.style.top = `${top}px`;
        dropMenu.style.left = `${left}px`;
      } else {
        console.log('[⚠️] No drop menu');
      }
    },
    selectEventHandle (evt) {
      // this.$emit('select', evt)
    },
    inputEventHandle (evt) {
      this.$emit('input', evt);
    },
    getSlotTextSingle (props) {
      if (typeof props.option === 'object') {
        return props.option[this.dynProps.label];
      }
      return props.option;
    },
    getSlotTextPre (props) {
      if (typeof props.option === 'object') {
        if (props.option.$isLabel) {
          return props.option.$groupLabel;
        }
        return props.option[this.dynProps.label]?.trim() || this.invalidTextVal;
      }
      return props.option;
    },
  },
};
</script>

<style lang="scss">
@import './multiselect.scss';

.multiselect-view {
  &.no-caret {
    .multiselect__select {
      visibility: hidden;
    }
  }

  &.no-border {
    .multiselect__tags {
      border: unset;
    }
  }

  .multiselect__tags {
    min-height: 30px;
    // min-height: 32px;
    padding: 4px 40px 0 8px;
    border-bottom: thin solid $light-border;
    font-size: 0.85rem;

    input {
      border-bottom: none;
    }
  }
  .multiselect__content-wrapper {
    position: absolute;
    background: $multiselect-back;
    // width: 100%;
    display: none;
    border: thin solid transparent;
    // border-top: none;

    .multiselect__option--selected {
      background-color: lighten($color: $multiselect-back, $amount: 5);
    }

    .multiselect__element {
      // padding: 4px 8px;
      width: 100%;
      border-radius: $button-border-radius-mini;

      &:first-child {
        margin-top: 5px;
      }

      &:hover {
        background: darken($color: $multiselect-back, $amount: 20);
        cursor: pointer;
      }

      .multiselect__option--highlight {
        width: 100%;
        background: darken($color: $multiselect-back, $amount: 10);
        // color: #fff;
      }

      .multiselect__option--highlight:after {
        content: '';
        background: #41b883;
        color: $multiselect-front;
      }

      .multiselect__option--group {
        background: #a5a5a5;
        color: #fff;
      }
    }

    .multiselect__option {
      padding: 4px 8px;
      display: block;
      width: 100%;
      color: $multiselect-front;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: 100%;
    }
  }

  &.static-drop {
    .multiselect__content-wrapper {
      position: static;
      // background: transparent;
      background: lighten($color: #0001, $amount: 0);
    }
  }

  &.fixed-drop {
    .multiselect__content-wrapper {
      position: fixed;
      z-index: 200;
      width: auto;
    }
  }

  &.has-options {
    .multiselect__content-wrapper {
      display: block;
      $alpha-darker: rgba($light-border, 0.2);
      border: thin solid darken($color: $alpha-darker, $amount: 50);
      border-top: unset;
      box-shadow: 0px 6px 16px 3px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
