export function debounce (func, waitTimer = 500, immediate = false) {
  let timeout = null;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitTimer);
    if (callNow) func.apply(context, args);
  };
}

export function throttle (func, waitTimer = 500) {
  let inThrottle = false;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, waitTimer);
    }
  };
}
