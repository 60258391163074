<template>
  <div id="client-table-list">
    <template>
      <div class="top-head">
        <div class="left">
          <!-- <span class="fa fa-arrow-left" @click="routeGoBackCheck()"></span> -->
          <span>{{$tc('app.client.title',2)}}</span>
        </div>
        <div class="center">
          <table-filters
            :values="routeQuery"
            :display="tableFilterDisplay"
            @search-input="routeQuery.search = $event.target.value"
            @active-status-select="$emit('active-status-select',$event)"
            @client-type-select="$emit('client-type-select',$event)"
          />
        </div>
        <div class="right">
          <router-link :to="{name: 'ClientAdd'}" v-if="checkHasPermission('create-clients')">
            <button class="acc">
              <span>
                <i class="fa fa-plus"></i>
                <span>{{$t('app.client.addNew')}}</span>
              </span>
            </button>
          </router-link>
        </div>
      </div>
      <transition name="slide-left">
        <div class="table-wrap" v-if="tableShowFunc">
          <table-wrapper
            class="table"
            :rows="clientsResponse.data"
            :columns="columnsToShow"
            :options="tableOptions"
            @row-select="rowSelect"
            @toggle-activation="$emit('toggle-activation', $event)"
          ></table-wrapper>
          <pagination-wrap
            :route-query="routeQuery"
            :pagination="pagination"
            @per-page-change="$emit('client-pagination-per-page',$event)"
          ></pagination-wrap>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'ClientTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  props: {
    clientsResponse: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      tableShowFunc: false,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
    };
  },
  computed: {
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'clt_name',
          label: this.$i18n.t('app.type'),
          style: {
            width: '150px',
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'activate',
          label: this.$i18n.t('selection.activeStatus'),
          permissions: 'activate-deactivate-clients',
          isMobileHeader: true,
          slots: {
            activate: true,
          },
        },
        {
          name: 'edit-client',
          label: '',
          permissions: 'edit-clients',
          style: {
            width: '150px',
          },
          slots: {
            editClient: true,
          },
        }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-clients'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        clientType: true,
        activeStatus: this.checkHasPermission('activate-deactivate-clients'),
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      // this.$emit('fetch-clients')
    },
    clientsResponse: {
      handler () {
        this.pagination.totalPages = this.clientsResponse.meta?.pageCount || 0;
      },
      deep: true,
    },
  },
  mounted () {
    this.pagination.totalPages = this.clientsResponse.meta?.pageCount || 0;
    this.$nextTick(() => (this.tableShowFunc = true));
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      setActiveClient: 'setActiveClient',
      setClientData: 'setClientData',
    }),
    async rowSelect (rowEvt) {
      try {
        const res = await this.setActiveClient({ cln_id: rowEvt.id });
        await this.setClientData(rowEvt);
        this.$router.push({
          name: 'GroupTableListAll',
          params: {
            client_id: rowEvt.id,
          },
        });
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#client-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
