<template>
  <div class="table-cell">
    <slot>
      <span :title="cellData">{{cellDataParsed}}</span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    column: Object,
    row: Object,
    cellData: {
      required: true,
    },
  },
  computed: {
    cellDataParsed () {
      if (this.column.type === 'date') {
        if (this.cellData) {
          const defaultFormat = this.column.format || 'DD MMM, YYYY';
          return this.convertDateFromIsoToLocalAndReverse(this.cellData, null, null, defaultFormat);
        }
      }
      return this.cellData;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell {
  padding: 14px 8px 14px 0;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;

  span {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
