<template>
  <div id="spinner-view">
    <spinner-comp :size="size"></spinner-comp>
  </div>
</template>

<script>
import SpinnerComp from './SpinnerComp.vue';
export default {
  components: {
    SpinnerComp,
  },
  props: {
    size: {
      type: Number,
      default: 40,
    },
  },
};
</script>

<style lang="scss" scoped>
#spinner-view {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
