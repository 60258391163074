
<div class="table-filters">
  <div class="search" v-if="displayLogic.search">
    <div class="input-group">
      <input
        :value="values.search"
        :placeholder="placeholderTranslations.search"
        :title="placeholderTranslations.search"
        type="text"
        @input="searchInput"
      />
    </div>
  </div>
  <div
    class="members"
    v-if="displayLogic.roles && checkHasPermission('view-roles')"
  >
    <div class="input-group">
      <multiselect-view
        :handleProps="roleProps"
        :handleEvents="roleEvents"
      ></multiselect-view>
    </div>
  </div>
  <div class="active-status" v-if="displayLogic.activeStatus">
    <div class="input-group">
      <multiselect-view
        :handleProps="activeStatusProps"
        :handleEvents="activeStatusEvents"
      ></multiselect-view>
    </div>
  </div>
  <div class="client-type" v-if="displayLogic.clientType">
    <div class="input-group">
      <multiselect-view
        :handleProps="clientTypesProps"
        :handleEvents="clientTypesEvents"
        :slot-text="multiSlotsMultiselect"
      />
    </div>
  </div>
  <div
    class="notification-filter"
    v-if="displayLogic.notification && checkHasPermission('create-messages')"
  >
    <div class="input-group">
      <multiselect-view
        :handleProps="notificationProps"
        :handleEvents="notificationEvents"
        :slot-text="multiSlotsMultiselect"
      />
    </div>
  </div>
</div>
