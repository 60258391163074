
<div id="group-add">
  <div class="form">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{ $tc("app.group.title", 1) }}</span>
    </div>
    <div class="input-group">
      <input
        class="alt"
        v-model.trim="form.grp_name"
        :placeholder="placeholderTranslations.name"
        type="text"
        required
      />
    </div>
    <div class="input-group">
      <textarea
        class="alt"
        v-model="form.grp_description"
        :placeholder="placeholderTranslations.description"
        :title="placeholderTranslations.description"
        type="text"
      />
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="locationsProps"
        :handleEvents="locationsEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <div class="input-group" v-if="groupId">
      <multiselect-view
        :handleProps="usersProps"
        :handleEvents="usersEvents"
        :slot-text="multiSlotsMultiselect"
      />
    </div>
    <div class="text-mini">* {{ $t("app.requiredFields") }}</div>
    <button class="acc" :disabled="isApiLoading" @click="manageGroup">
      <span :class="{ progress: isApiLoading }">
        <i class="fa fa-save"></i>
        <span>{{ $t("app.save") }}</span>
      </span>
    </button>
  </div>
</div>
