<template>
  <div class="table-filters">
    <div class="search" v-if="displayLogic.search">
      <div class="input-group">
        <input
          :value="values.search"
          :placeholder="placeholderTranslations.search"
          :title="placeholderTranslations.search"
          type="text"
          @input="searchInput"
        />
      </div>
    </div>
    <div
      class="members"
      v-if="displayLogic.roles && checkHasPermission('view-roles')"
    >
      <div class="input-group">
        <multiselect-view
          :handleProps="roleProps"
          :handleEvents="roleEvents"
        ></multiselect-view>
      </div>
    </div>
    <div class="active-status" v-if="displayLogic.activeStatus">
      <div class="input-group">
        <multiselect-view
          :handleProps="activeStatusProps"
          :handleEvents="activeStatusEvents"
        ></multiselect-view>
      </div>
    </div>
    <div class="client-type" v-if="displayLogic.clientType">
      <div class="input-group">
        <multiselect-view
          :handleProps="clientTypesProps"
          :handleEvents="clientTypesEvents"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
    </div>
    <div
      class="notification-filter"
      v-if="displayLogic.notification && checkHasPermission('create-messages')"
    >
      <div class="input-group">
        <multiselect-view
          :handleProps="notificationProps"
          :handleEvents="notificationEvents"
          :slot-text="multiSlotsMultiselect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { debounce } from '@/assets/js/helpers.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  props: {
    values: {
      type: Object,
      default: () => ({
        search: '',
      }),
    },
    display: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      form: {
        rls_id: 0,
        is_user_message: null,
        usr_active: true,
      },
      defaultDisplay: {
        search: true,
        clientType: false,
      },
      clientTypes: [],
      roleOptions: [],
    };
  },
  computed: {
    displayLogic () {
      return {
        ...this.defaultDisplay,
        ...this.display,
      };
    },
    roleAllOptions () {
      return [
        {
          rls_name: this.$i18n.t('app.allUsers'),
          rls_id: 0,
          rls_description: '',
        },
        ...this.roleOptions
      ];
    },
    notificationOptions () {
      return [
        {
          label: this.$i18n.t('app.notificationStatus.all'),
          value: null,
          id: 1,
        },
        {
          label: this.$i18n.t('app.notificationStatus.myMessages'),
          value: true,
          id: 2,
        },
        {
          label: this.$i18n.t('app.notificationStatus.messagesForMe'),
          value: false,
          id: 3,
        }
      ];
    },
    activeStatusOptions () {
      return [
        {
          label: this.$i18n.t('app.activeStatus.all'),
          value: null,
          id: 1,
        },
        {
          label: this.$i18n.t('app.activeStatus.active'),
          value: true,
          id: 2,
        },
        {
          label: this.$i18n.t('app.activeStatus.inactive'),
          value: false,
          id: 3,
        }
      ];
    },
    roleProps () {
      return {
        // __isFixedDrop: true,
        options: this.roleAllOptions,
        placeholder: this.placeholderTranslations.member,
        title: this.placeholderTranslations.member,
        value: this.roleAllOptions.find(item => item.rls_id === this.form.rls_id),
        searchable: true,
        label: 'rls_name',
      };
    },
    roleEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'rls_id', evt.rls_id);
          this.$emit('role-select', this.form.rls_id);
        },
        remove: () => {
          this.$set(this.form, 'rls_id', null);
          this.$emit('role-select', null);
        },
      };
    },
    activeStatusProps () {
      return {
        options: this.activeStatusOptions,
        placeholder: this.placeholderTranslations.activeStatus,
        title: this.placeholderTranslations.activeStatus,
        value: this.activeStatusOptions.find(item => item.value === this.form.usr_active),
        searchable: true,
        allowEmpty: false,
        label: 'label',
      };
    },
    activeStatusEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'usr_active', evt.value);
          this.$emit('active-status-select', this.form.usr_active);
        },
        remove: () => {
          this.$set(this.form, 'rls_id', null);
          this.$emit('active-status-select', null);
        },
      };
    },
    notificationProps () {
      return {
        options: this.notificationOptions,
        placeholder: this.placeholderTranslations.messagesFilter,
        title: this.placeholderTranslations.messagesFilter,
        value: this.notificationOptions.find(item => item.value === this.form.is_user_message),
        allowEmpty: false,
        label: 'label',
      };
    },
    notificationEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'is_user_message', evt.value);
          this.$emit('notification-select', this.form.is_user_message);
        },
        remove: () => {
          this.$set(this.form, 'is_user_message', null);
          this.$emit('notification-select', null);
        },
      };
    },
    clientTypesProps () {
      return {
        // __isFixedDrop: true,
        options: this.clientTypes,
        placeholder: this.placeholderTranslations.clientType,
        title: this.placeholderTranslations.clientType,
        value: this.clientTypes.find(item => item.clt_id === this.form.clt_id),
        searchable: true,
        label: 'clt_name',
      };
    },
    clientTypesEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'clt_id', evt.clt_id);
          this.$emit('client-type-select', this.form.clt_id);
        },
        remove: () => {
          this.$set(this.form, 'clt_id', null);
          this.$emit('client-type-select', null);
        },
      };
    },
  },
  watch: {
    langChangeEventCo () {
      this.fetchClientTypesCheck();
    },
  },
  mounted () {
    if (this.displayLogic.roles) {
      this.fetchRoles();
    }
    this.fetchClientTypesCheck();
  },
  methods: {
    ...mapActions({
      getRoles: 'getRoles',
      getClientTypes: 'getClientTypes',
    }),
    async fetchRoles () {
      const params = {};
      try {
        const res = await this.getRoles(params);
        this.roleOptions.push(...res.data.data);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    fetchClientTypesCheck () {
      if (this.displayLogic.clientType) {
        this.fetchClientTypes();
      }
    },
    async fetchClientTypes () {
      try {
        const res = await this.getClientTypes({});
        this.clientTypes = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    searchInput: debounce(function (evt) {
      this.$emit('search-input', evt);
      const searchVal = evt.target.value;
      const currentQuery = {
        ...this.$route.query,
        page: 1, // Reset to first page
        search: searchVal,
      };
      if (!searchVal) {
        this.$delete(currentQuery, 'search');
      }
      this.$router.push({
        query: currentQuery,
      });
    }),
  },
};
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  .search,
  .members,
  .active-status,
  .client-type,
  .notification-filter {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  .search {
    input {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }

  .members {
    // Remove later
    display: flex;
    align-items: center;

    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 180px;
      }
    }

    > span {
      display: flex;
      align-items: center;
    }
  }

  .active-status {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 120px;
      }
    }
  }

  .client-type {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 190px;
      }
    }
  }

  .notification-filter {
    .multiselect-view {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 190px;
      }
    }
  }
}
</style>
