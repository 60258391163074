<template>
  <div id="membership-types-add">
    <div class="form" @input="inputCapture">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{$tc('app.membershipType.title',1)}}</span>
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model="form.mmt_name"
          :placeholder="placeholderTranslations.name"
          :title="placeholderTranslations.name"
          type="text"
          required
        />
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="currenciesProps"
          :handleEvents="currenciesEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group">
        <input
          class="alt price"
          v-model="form.mmt_amount"
          :placeholder="placeholderTranslations.price"
          :title="placeholderTranslations.price"
          min="0"
          type="number"
          required
        />
      </div>
      <div class="input-group">
        <textarea
          class="alt max-w"
          v-model="form.mmt_description"
          :placeholder="placeholderTranslations.description"
          :title="placeholderTranslations.description"
          type="text"
        />
      </div>
      <div class="text-mini">* {{$t('app.requiredFields')}}</div>
      <button class="acc long" :disabled="isApiLoading" @click="manageMembership">
        <span :class="{'progress': isApiLoading}">
          <i class="fa fa-save"></i>
          <span>{{$t('app.save')}}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      membershipTypeId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      currencies: [],
      transformMap: {
        // 'mmb_id': 'id' - not used
        mmt_id: 'id',
        usr_id_member: 'usr_id',
      },
      includeKeys: [
        'id',
        'mmt_id',
        'cur_id',
        'mmt_name',
        'mmt_amount',
        'mmt_description'
      ],
    };
  },
  computed: {
    currenciesProps () {
      return {
        // __isFixedDrop: true,
        options: this.currencies,
        placeholder: this.placeholderTranslations.currency,
        title: this.placeholderTranslations.currency,
        value: this.currencies.find(item => item.cur_id === this.form.cur_id),
        searchable: true,
        label: 'cur_code',
      };
    },
    currenciesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'cur_id', evt.cur_id),
        remove: () => this.$set(this.form, 'cur_id', null),
      };
    },
  },
  mounted () {
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetMembershipTypeId();
    this.fetchCurrencies();
  },
  methods: {
    ...mapActions({
      getCurrencies: 'getCurrencies',
      getOneMembershipType: 'getOneMembershipType',
      postMembershipTypes: 'postMembershipTypes',
      patchMembershipType: 'patchMembershipType',
    }),
    checkGetMembershipTypeId () {
      this.membershipTypeId = parseInt(this.$route.params.membership_type_id, 10) || null;
      if (this.membershipTypeId) {
        this.fetchMembershipTypes();
      }
    },
    async fetchMembershipTypes () {
      try {
        const res = await this.getOneMembershipType({ id: this.membershipTypeId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.form);
    },
    async fetchCurrencies () {
      const params = {};
      try {
        const res = await this.getCurrencies(params);
        this.currencies = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async manageMembership () {
      this.isApiLoading = true;
      if (this.membershipTypeId) {
        await this.editMembership();
      } else {
        await this.saveMembership();
      }
      this.isApiLoading = false;
    },
    async editMembership () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchMembershipType(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.membership);
    },
    async saveMembership () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postMembershipTypes(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.membership);
    },
    inputCapture (evt) {
      console.log('input', evt);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#membership-types-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }
}
</style>
