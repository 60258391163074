<template>
  <div class="custom-table-list">
    <template v-if="columnsFiltered.length">
      <div class="header">
        <span
          class="cell"
          :style="[columnCellStyle(column)]"
          v-for="column in columnsFiltered"
          :key="column.id"
        >
          <span>{{ column.label }}</span>
        </span>
      </div>
      <div class="body">
        <div
          class="row"
          :class="dynRowClass(row)"
          v-for="(row, rowIndex) in rows"
          :key="row.id"
          @click="rowCardSelected($event, row)"
        >
          <div
            class="cell"
            :class="{ slot: column.slots && Object.keys(column.slots).length }"
            :style="[columnCellStyle(column)]"
            v-for="column in columnsFiltered"
            :key="column.id"
          >
            <table-cell
              :cell-data="row[column.name]"
              :row="row"
              :column="column"
            >
              <!-- Remove btn -->
              <template 
                v-if="
                  column.slots &&
                  column.slots.remove &&
                  column.canShowCellFunc &&
                  column.canShowCellFunc(row)
                ">
                <v-popover :ref="'popover-remove-' + rowIndex">
                  <button
                    class="mini close-btn"
                    @click.stop="showPopoverRemove(rowIndex)"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <template slot="popover">
                    <div class="popover-action-slot">
                      <span>{{ $t('app.confirmProceed') }}?</span>
                      <div class="action">
                        <button
                          class="acc"
                          @click.stop="remove($event, row, rowIndex)"
                          v-close-popover
                        >
                          <span>
                            <span>{{ $t('app.confirm') }}</span>
                          </span>
                        </button>
                        <button
                          @click.stop.prevent
                          v-close-popover
                        >
                          <span>
                            <span>{{ $t('app.cancel') }}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </template>
                </v-popover>
              </template>
              <!-- Classes btn -->
              <template v-if="column.slots && column.slots.classes">
                <router-link
                  :to="classTableListRoute(clientData, row)"
                  class="classes-btn"
                  :title="$i18n.tc('app.class.title', 2)"
                >
                  <i class="fa fa-graduation-cap"></i>
                  <span>{{ $tc('app.class.title', 2) }}</span>
                </router-link>
              </template>
              <!-- Membership btn -->
              <template v-if="column.slots && column.slots.memberships">
                <router-link
                  :to="{
                    name: 'MembershipTableUser',
                    params: { client_id: clientData.id, user_id: row.id },
                  }"
                  class="classes-btn"
                  :title="$tc('app.membership.title', 2)"
                >
                  <button class="mini acc alt">
                    <span>
                      <i class="fa fa-id-card"></i>
                      <span>{{ $tc('app.membership.title', 2) }}</span>
                    </span>
                  </button>
                </router-link>
              </template>
              <!-- Edit Group -->
              <template v-if="column.slots && column.slots.editGroup">
                <router-link
                  :to="{
                    name: 'GroupEdit',
                    params: { client_id: clientData.id, group_id: row.id },
                  }"
                  class="group-btn"
                >
                  <i class="fa fa-pen"></i>
                  <span>{{ $t('app.editGroup') }}</span>
                </router-link>
              </template>
              <!-- Edit Group -->
              <template v-if="column.slots && column.slots.editClient">
                <router-link
                  :to="{ name: 'ClientEdit', params: { client_id: row.id } }"
                  class="group-btn"
                >
                  <i class="fa fa-pen"></i>
                  <span>{{ $t('app.editClient') }}</span>
                </router-link>
              </template>
              <!-- Active Toggle Switch -->
              <template v-if="column.slots && column.slots.activate">
                <v-popover :ref="'popover-status-' + rowIndex">
                  <checkbox-input
                    class="tooltip-target b3"
                    :checked="row._isActiveToggle === 1"
                    :options="mergedOptions.activationOptions"
                    @change="showPopoverActive(rowIndex)"
                    @click.native.stop
                  ></checkbox-input>
                  <template slot="popover">
                    <div class="popover-action-slot">
                      <span>{{ $t('app.confirmProceed') }}?</span>
                      <div class="action">
                        <button
                          class="acc"
                          @click.stop="toggleActivation($event, row, rowIndex)"
                          v-close-popover
                        >
                          <span>
                            <span>{{ $t('app.confirm') }}</span>
                          </span>
                        </button>
                        <button
                          @click.stop.prevent
                          v-close-popover
                        >
                          <span>
                            <span>{{ $t('app.cancel') }}</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </template>
                </v-popover>
              </template>
            </table-cell>
          </div>
        </div>
      </div>
    </template>
    <div
      class="no-data"
      v-if="columnsFiltered.length === 0"
    >
      <span>{{ $t('app.table.noData') }}</span>
    </div>
  </div>
</template>

<script>
import TableCell from '@/components/Shared/table/TableCell.vue';
import tableMixin from './tableMixin.js';
export default {
  components: {
    TableCell,
  },
  mixins: [tableMixin],
};
</script>

<style lang="scss" scoped>
.custom-table-list {
  @include table-elements;
  $card-shadow: 0px 1px 7px 1px $global-shadow-color;
  display: grid;
  flex-direction: column;
  padding: 10px 2px 10px 2px;
  color: #a19fa8;
  font-weight: 500;
  overflow: auto;

  .header {
    display: flex;
    column-gap: 5px;
    margin: 0 8px 20px 8px;
    padding: 13px 10px 15px;
    border-radius: 6px;
    background: lighten($color: $accent-color-light, $amount: 0);
    color: $accent-color;
    // box-shadow: $card-shadow;
    user-select: none;
    overflow: hidden;
    border-left: 6px solid;
    border-left-color: transparent;
    // min-width: 0;

    // > .cell {
    //   flex: 1 1 100px;
    // }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: $table-text;
    // min-width: 0;
    // overflow: hidden;

    .row {
      display: flex;
      column-gap: 5px;
      padding: 0 10px;
      margin: 0 8px 0 8px;
      background: white;
      box-shadow: $card-shadow;
      border-radius: 6px;
      transition: all 0.2s ease;
      border-left: 6px solid;
      border-left-color: transparent;
      overflow: hidden;

      &.selectable {
        user-select: none;
        cursor: pointer;
      }

      &.disabled-low {
        background: #00000012;
        opacity: 0.7;
        cursor: not-allowed;
      }

      &:hover {
        border-left-color: $accent-color;
        // transform: translateX(4px);
        box-shadow: 0px 2px 7px 0px darken($global-shadow-color, 50);
      }

      > .cell {
        display: inline-block;
        // flex: 1 1 100px;
      }
    }
  }
}
</style>
