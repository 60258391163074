
<div class="checkbox-wrap" :class="{checked: getChecked}">
  <div class="checkbox-group" @click="changeRefInput">
    <label
      class="left"
      :class="options.labelClass"
      for="checkbox-input"
      v-if="getLeftText"
    >{{getLeftText}}</label>

    <span
      id="checkbox-input"
      :checked="getChecked"
      :tabindex="tabIndexBasedOnDisabled"
      @keyup.space="changeRefInput"
    >
      <template v-if="isCheckbox">
        <div class="checkbox-inner-box">
          <svg width="16" height="14" viewBox="0 0 16 14">
            <path d="M2 8.5L6 12.5L14 1.5" />
          </svg>
        </div>
      </template>
      <template v-if="!isCheckbox">
        <div class="toggle-wrap">
          <div class="toggle">
            <div class="switch"></div>
          </div>
        </div>
      </template>
    </span>

    <label
      class="right"
      :class="options.labelClass"
      for="checkbox-input"
      v-if="getRightText"
    >{{getRightText}}</label>
  </div>

  <input
    class="hidden"
    :style="[{visibility: `hidden`} ,{position:`absolute`}]"
    :checked="getChecked"
    :disabled="options.disabled"
    @change="changeVal"
    ref="input"
    type="checkbox"
  />
</div>
