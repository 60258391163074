import permissionHelper from '@/assets/js/permissionHelper.js';
import i18n from '@/i18n.js';
import Vue from 'vue';
import Vuex from 'vuex';
import { baseURL } from '../api/config.js';
import api from '../api/index.js';

Vue.use(Vuex);

const vuexStore = {
  state: {
    isMobileView: false,
    appLangSelected: {},
    appLangEventCo: 0,
    appFrontVersion: 0,
    paginationOptionNumber: 5,
    notificationCount: 0,
    langOptions: [
      {
        id: 1,
        label: 'en',
        lang: 'en',
      },
      {
        id: 2,
        label: 'sr',
        lang: 'sr',
      },
    ],
    userData: {},
    clientData: {}, // Needed to show the working app + app navbar
    appNavItems: [
      {
        id: 0,
        label: 'app.user.title',
        route: 'UserTableListAll',
        permissions: 'view-users',
      },
      {
        id: 1,
        label: 'app.group.title',
        route: 'GroupTableListAll',
        permissions: 'view-groups',
      },
      {
        id: 2,
        label: 'app.management',
        // route: 'GroupTableListAll',
        children: [
          {
            label: 'app.location.title',
            route: 'LocationTableListAll',
            permissions: 'view-locations',
          },
          {
            label: 'app.membershipType.title',
            route: 'MembershipTypesTableListAll',
            permissions: 'view-membershiptypes',
          },
          // {
          //   label: 'app.class.title',
          //   route: 'ClassTableListAll'
          // }
        ],
      },
      {
        id: 3,
        label: 'app.membership.title',
        route: 'MembershipTableListAll',
        permissions: 'view-memberships',
      },
      {
        id: 4,
        label: 'app.client.title',
        route: 'ClientTableListAll',
        // permissions: 'view-clients'
      },
    ],
    homepageNavItems: [
      {
        id: 0,
        label: 'homepage.benefits.title',
        route: 'benefits',
      },
      {
        id: 1,
        label: 'homepage.home',
        route: 'home',
      },
      {
        id: 2,
        label: 'homepage.suitableFor.title',
        route: 'suitable',
      },
      // {
      //   id: 3,
      //   label: 'homepage.pricing',
      //   route: 'pricing'
      // },
      {
        id: 4,
        label: 'homepage.contact',
        route: 'contact',
      },
    ],
    clientResponse: [],
  },
  getters: {
    isMultipleClients: state => state.clientResponse.userClientsCount > 1,
    checkHasPermission: state => key => {
      if (Object.keys(state.userData).length) {
        return permissionHelper.canAccessPermission(key, state.userData);
      }
      // From browser storage
      console.log('>>> Permissions from web storage');
      return permissionHelper.canAccessPermission(key);
    },
    homepageNavItems: state => {
      return state.homepageNavItems.map(item => {
        return {
          ...item,
          label: i18n.tc(item.label, 2),
          children: (item.children || []).map(child => {
            return {
              ...child,
              label: i18n.tc(child.label, 2),
            };
          }),
        };
      });
    },
    manualLink: (state, getters) => {
      let rootUrl = baseURL || location.origin;
      if (process.env.NODE_ENV === 'production') {
        rootUrl = location.origin;
      }
      return new URL('uputstvo.pdf', rootUrl);
    },
    filteredAppNavItems: (state, getters) => {
      const clientRoute = 'ClientTableListAll';

      const formatItem = (item) => {
        return {
          ...item,
          label: i18n.tc(item.label, 2),
          children: (item.children || []).map(child => {
            return {
              ...child,
              label: i18n.tc(child.label, 2),
            };
          }),
        };
      };

      return state.appNavItems.reduce((acc, navItem) => {
        if (!getters.isMultipleClients && navItem.route === clientRoute) {
          return acc;
        }
        const canAccess = (item) => {
          if (item.children && !item.permissions) {
            return item.children.some(itemChild => getters.checkHasPermission(itemChild.permissions));
          }
          return getters.checkHasPermission(item.permissions);
        };
        if (canAccess(navItem)) {
          acc.push(formatItem(navItem));
        }

        return acc;
      }, []);
    },
  },
  mutations: {
    SET_USER_DATA: (state, payload) => (state.userData = payload),
    SET_MOB_VIEW: (state, payload) => (state.isMobileView = payload),
    SET_CLIENT_DATA: (state, payload) => (state.clientData = payload),
    SET_NOTIF_COUNT: (state, payload) => (state.notificationCount = payload),
    SET_LANG_DATA: (state, payload) => {
      state.appLangSelected = payload;
      state.appLangEventCo++;
    },
    SET_PAGINATION_NUM_DATA: (state, payload) =>
      (state.paginationOptionNumber = payload),
    SET_APP_VER: (state, payload) => (state.appFrontVersion = payload),
    STORE_CLIENT_META: (state, payload) => (state.clientResponse = payload),
  },
  actions: {
    setUserData: ({ commit }, payload) => commit('SET_USER_DATA', payload),
    setMobileView: ({ commit }, isSet) => commit('SET_MOB_VIEW', isSet),
    setClientData: ({ commit }, payload) => commit('SET_CLIENT_DATA', payload),
    saveLangStore: ({ commit }, payload) => commit('SET_LANG_DATA', payload),
    setPaginationData: ({ commit }, payload) => commit('SET_PAGINATION_NUM_DATA', payload),
    updateAppVersionStore: ({ commit }, payload) => commit('SET_APP_VER', payload),
    // API
    checkNotificationCount: async ({ commit }, payload) => {
      try {
        const res = await api.checkNotificationCount();
        if (res.data.data || res.data.data === 0) {
          commit('SET_NOTIF_COUNT', res.data.data);
        }
      } catch (err) {
        // ignored
      }
    },
    postLogin: ({ c }, payload) => api.loginApi(payload),
    logoutApi: ({ c }, payload) => api.logoutApi(payload),
    postRegister: ({ c }, payload) => api.registerApi(payload),
    postCheckEmail: ({ c }, payload) => api.postCheckEmail(payload),
    postMessage: ({ c }, payload) => api.postMessage(payload),
    setActiveClient: ({ c }, payload) => api.setActiveClient(payload),
    expandSession: ({ c }, payload) => api.expandSession(payload),
    getProfile: ({ c }, payload) => api.getProfile(payload),
    patchProfile: ({ c }, payload) => api.patchProfile(payload),
    patchConfig: ({ c }, payload) => api.patchConfig(payload),
    getOneClient: ({ c }, payload) => api.getOneClient(payload),
    patchClient: ({ c }, payload) => api.patchClient(payload),
    getClients: async ({ commit }, payload) => {
      const res = await api.getClients(payload);
      commit('STORE_CLIENT_META', res.data);
      return res;
    },
    postClients: ({ c }, payload) => api.postClients(payload),
    getOneGroup: ({ c }, payload) => api.getOneGroup(payload),
    getGroups: ({ c }, payload) => api.getGroups(payload),
    patchGroup: ({ c }, payload) => api.patchGroup(payload),
    postGroups: ({ c }, payload) => api.postGroups(payload),
    getCurrencies: ({ c }, payload) => api.getCurrencies(payload),
    getOneMembershipType: ({ c }, payload) => api.getOneMembershipType(payload),
    getMembershipTypes: ({ c }, payload) => api.getMembershipTypes(payload),
    patchMembershipType: ({ c }, payload) => api.patchMembershipType(payload),
    postMembershipTypes: ({ c }, payload) => api.postMembershipTypes(payload),
    getOneMembership: ({ c }, payload) => api.getOneMembership(payload),
    getMemberships: ({ c }, payload) => api.getMemberships(payload),
    patchMembership: ({ c }, payload) => api.patchMembership(payload),
    postMemberships: ({ c }, payload) => api.postMemberships(payload),
    getOneUser: ({ c }, payload) => api.getOneUser(payload),
    patchUser: ({ c }, payload) => api.patchUser(payload),
    getUsers: ({ c }, payload) => api.getUsers(payload),
    postUsers: ({ c }, payload) => api.postUsers(payload),
    getOneLocation: ({ c }, payload) => api.getOneLocation(payload),
    patchLocation: ({ c }, payload) => api.patchLocation(payload),
    getLocations: ({ c }, payload) => api.getLocations(payload),
    postLocations: ({ c }, payload) => api.postLocations(payload),
    getOneClass: ({ c }, payload) => api.getOneClass(payload),
    patchClass: ({ c }, payload) => api.patchClass(payload),
    getClasses: ({ c }, payload) => api.getClasses(payload),
    getClassesInstructors: ({ c }, payload) => api.getClassesInstructors(payload),
    postClasses: ({ c }, payload) => api.postClasses(payload),
    getCountries: ({ c }, payload) => api.getCountries(payload),
    getCities: ({ c }, payload) => api.getCities(payload),
    getClientTypes: ({ c }, payload) => api.getClientTypes(payload),
    getRoles: ({ c }, payload) => api.getRoles(payload),
    getNotificationList: ({ c }, payload) => api.getNotificationList(payload),
    getOneNotification: ({ c }, payload) => api.getOneNotification(payload),
    postNotification: ({ c }, payload) => api.postNotification(payload),
    patchNotification: ({ c }, payload) => api.patchNotification(payload),
    deleteNotification: ({ c }, payload) => api.deleteNotification(payload),
  },
  modules: {},
};

const setDefaultLangStart = () => {
  // Set first lang on load

  // Rework in future
  const langFromUrl = document.location.pathname.split('/').filter(Boolean)[0];
  let foundObj = null;
  if (langFromUrl) {
    foundObj = vuexStore.state.langOptions.find(lang => lang.lang === langFromUrl);
    if (foundObj) {
      i18n.locale = langFromUrl;
      if (!localStorage.lang) {
        localStorage.lang = langFromUrl;
      }
    }
  }
  vuexStore.state.appLangSelected = foundObj || vuexStore.state.langOptions[0];
};
setDefaultLangStart();

export default new Vuex.Store(vuexStore);
