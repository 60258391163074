
<div>
  <template v-if="isFullTable">
    <table-list
      class="table"
      :rows="rows"
      :columns="columns"
      :options="options"
      @row-select="$emit('row-select',$event)"
      @click-remove="$emit('click-remove',$event)"
      @toggle-activation="$emit('toggle-activation',$event)"
    >
      <slot></slot>
    </table-list>
  </template>
  <template v-else>
    <table-mobile-list
      class="table"
      :rows="rows"
      :columns="columns"
      :options="options"
      @row-select="$emit('row-select',$event)"
      @click-remove="$emit('click-remove',$event)"
      @toggle-activation="$emit('toggle-activation',$event)"
    >
      <slot></slot>
    </table-mobile-list>
  </template>
</div>
