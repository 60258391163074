
<div id="membership-add">
  <div class="form" @input="inputCapture">
    <div class="close-btn" @click="routeGoBackCheck()">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{$tc('app.membership.title',1)}}</span>
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="usersProps"
        :handleEvents="usersEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <!-- <div class="input-group">
    <input
      class="alt"
      disabled
      :value="convertDateFromIsoToLocalAndReverse(selectedUser.max_mmb_date_to, null,null,'DD. MMM. YYYY')"
      :placeholder="placeholderTranslations.lastMembershipDate"
      :title="placeholderTranslations.lastMembershipDate"
    />
    </div>-->
    <div class="input-group">
      <datepicker-wrapper
        :value="selectedUser.max_mmb_date_to"
        :placeholder="placeholderTranslations.lastMembershipDate"
        :title="placeholderTranslations.lastMembershipDate"
        disabled
      />
    </div>
    <!-- <div class="input-group">
    <input
      class="alt"
      v-model.trim="fullUserName"
      disabled
      :title="placeholderTranslations.name"
      :placeholder="placeholderTranslations.name"
      type="text"
      v-if="fullUserName.trim()"
    />
    </div>-->
    <div class="input-group">
      <datepicker-wrapper
        v-model="form.mmb_payment_date"
        :placeholder="placeholderTranslations.paymentDate"
        :title="placeholderTranslations.paymentDate"
        required
      />
    </div>
    <div class="input-group">
      <datepicker-wrapper
        v-model="form.mmb_date_to"
        :placeholder="placeholderTranslations.expireDate"
        :title="placeholderTranslations.expireDate"
        required
      />
    </div>
    <div class="input-group">
      <multiselect-view
        :handleProps="membershipTypesProps"
        :handleEvents="membershipTypesEvents"
        :slot-text="multiSlotsMultiselect"
        required
      />
    </div>
    <div class="input-group">
      <input
        class="alt price"
        v-model="form.mmb_amount"
        :placeholder="placeholderTranslations.price"
        :title="placeholderTranslations.price"
        min="0"
        type="number"
        required
      />
    </div>
    <span v-if="displayCurrency">{{form.cur_code}}</span>
    <div class="input-group">
      <textarea
        class="alt max-w"
        v-model="form.mmb_description"
        :placeholder="placeholderTranslations.description"
        :title="placeholderTranslations.description"
        type="text"
        :required="isMmbDifferentMmtAmounts"
      />
    </div>
    <div class="text-mini">* {{$t('app.requiredFields')}}</div>
    <button class="acc long" :disabled="isApiLoading" @click="manageMembership">
      <span :class="{'progress': isApiLoading}">
        <i class="fa fa-save"></i>
        <span>{{$t('app.save')}}</span>
      </span>
    </button>
  </div>
</div>
