<template>
  <div id="membership-types-table-list">
    <div class="top-head">
      <div class="left">
        <router-link :to="getDynamicPreviousRoute" class="btn" v-if="canGoBack">
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>
        <span>{{ $tc('app.membershipType.title', 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="activeStatusSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{ name: 'MembershipTypesAdd' }"
          v-if="checkHasPermission('create-membershiptypes')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t('app.membershipType.addNew') }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="membershipTypesData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @toggle-activation="toggleActiveState"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'MembershipTypesTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  data () {
    return {
      membershipTypesData: [],
      currencies: [],
      tableShowFunc: false,
      activeStatusSelection: true,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        mmt_id: 'id',
        mmt_name: 'name',
        mmt_description: 'description',
        mmt_phone: 'phone',
        mmt_amount: 'amount',
        mmt_active: '_isActiveToggle',
      },
    };
  },
  computed: {
    canGoBack () {
      return this.$route.name !== 'MembershipTypesTableListAll';
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '250px',
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '250px',
          },
        },
        {
          name: 'cur_code',
          label: this.$i18n.t('app.currency'),
          style: {
            width: '100px',
          },
        },
        {
          name: 'amount',
          label: this.$i18n.t('app.amount'),
          style: {
            width: '150px',
          },
        },
        {
          name: 'activate',
          label: this.$i18n.t('selection.activeStatus'),
          permissions: 'activate-deactivate-membershiptypes',
          isMobileHeader: true,
          slots: {
            activate: true,
          },
        }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-membershiptypes'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        activeStatus: this.checkHasPermission('activate-deactivate-membershiptypes'),
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchMembershipTypesList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchData();
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getMembershipTypes: 'getMembershipTypes',
      getCurrencies: 'getCurrencies',
      patchMembershipType: 'patchMembershipType',
    }),
    async fetchData () {
      await this.fetchCurrencies();
      await this.fetchMembershipTypesList();
      this.isLoadingData = false;
    },
    async fetchCurrencies () {
      const params = {};
      try {
        const res = await this.getCurrencies(params);
        this.currencies = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchMembershipTypesList () {
      const params = {
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        mmt_active: this.activeStatusSelection,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getMembershipTypes(params);
        let tempData = res.data.data || [];
        tempData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.membershipTypesData = this.addAdditionalData(tempData);
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchMembershipTypesList();
    },
    async toggleActiveState (event) {
      // console.log('toggleActiveState', evt);
      const params = {
        id: event.row.id,
        mmt_active: event.evt.checked,
      };
      try {
        const res = await this.patchMembershipType(params);
        this.showSuccessMsg(res.data.message);
        this.fetchMembershipTypesList();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async rowSelect (rowEvt) {
      this.$router.push({
        name: 'MembershipTypesEdit',
        params: {
          membership_type_id: rowEvt.id,
        },
      });
    },
    addAdditionalData (tempData) {
      const getCurrencyName = (data) => this.currencies.find(currency => currency.cur_id === data.cur_id)?.cur_name;
      const getCurrencyCode = (data) => this.currencies.find(cur => cur.cur_id === data.cur_id).cur_code;

      for (const data of tempData) {
        data.cur_name = getCurrencyName(data);
        data.cur_code = getCurrencyCode(data);
      }
      return tempData;
    },
  },
};
</script>

<style lang="scss" scoped>
#membership-types-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
