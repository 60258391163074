
<div id="location-table-list">
  <div class="top-head">
    <div class="left">
      <!-- <router-link
        :to="{name:'LocationTableList'}"
        v-if="checkHasPermission('view-locations')"
        class="btn"
      >
        <span>
          <i class="fa fa-chevron-left"></i>
          <span>{{ $t('app.back') }}</span>
        </span>
      </router-link>-->
      <span>{{ $tc('app.location.title', 2) }}</span>
      <span
        :title="$i18n.tc('app.client.title', 1)"
        v-if="isMultipleClients && clientData.name"
        >({{ clientData.name }})</span
      >
    </div>
    <div class="center">
      <table-filters
        :values="routeQuery"
        :display="tableFilterDisplay"
        @search-input="routeQuery.search = $event.target.value"
        @active-status-select="activeStatusSelected"
      />
    </div>
    <div class="right">
      <router-link
        :to="{ name: 'LocationAdd' }"
        v-if="checkHasPermission('create-locations')"
      >
        <button class="acc">
          <span>
            <i class="fa fa-plus"></i>
            <span>{{ $t('app.location.addNew') }}</span>
          </span>
        </button>
      </router-link>
    </div>
  </div>
  <transition name="slide-left">
    <div class="table-wrap" v-if="tableShowFunc">
      <table-wrapper
        class="table"
        :rows="locationsData"
        :columns="columnsToShow"
        :options="tableOptions"
        @row-select="rowSelect"
        @toggle-activation="toggleActiveState"
      ></table-wrapper>
      <pagination-wrap
        :route-query="routeQuery"
        :pagination="pagination"
      ></pagination-wrap>
    </div>
  </transition>
  <spinner-view
    :size="70"
    v-if="!tableShowFunc && !isLoadingData"
  ></spinner-view>
</div>
