import axios from 'axios';
import router from '../router';

const baseURL = import.meta.env.VITE_APP_ROOT_API;
const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(config => {
  handleRequestInterceptor(config);

  return config;
});

instance.interceptors.response.use(response => {
  // handleResponseInterceptor(response)

  return response;
}, error => {
  handleResponseErrorInterceptor(error);

  return Promise.reject(error);
});

const apiCheckSession = () => Boolean(localStorage.sid);
const LOGIN_COMP_NAME = 'LoginPage';
const RESPONSE_STATUS_CODE = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
};

function handleResponseErrorInterceptor (error) {
  const isValidSes = apiCheckSession();
  const statusNum = error.response.status;
  if (isValidSes === false || statusNum === RESPONSE_STATUS_CODE.unauthorized) {
    if (statusNum === RESPONSE_STATUS_CODE.unauthorized) {
      removeResetSession();
    }
    console.log('[⚠️ - ERR] xhr config: ', error.response);
  } else if (statusNum === RESPONSE_STATUS_CODE.forbidden) {
    // ignored
  } else if (statusNum === RESPONSE_STATUS_CODE.notFound) {
    // Return to first page
    console.log('API RES: Not Found');
    router.replace({
      name: 'ClientTableListAll',
    });
  }
}

function handleRequestInterceptor (config) {
  try {
    const sid = localStorage.sid;
    const lang = localStorage.lang || 'en';
    if (config.params) {
      config.params.sid = sid;
      config.params.lang = lang;
    } else {
      config.params = {
        sid,
        lang,
      };
    }
  } catch (err) {
    console.log('eRRor', err);
  }
}

function removeResetSession () {
  if (router.currentRoute.name !== LOGIN_COMP_NAME) {
    console.log('[SESSION] - Removing');
    localStorage.clear();
    router.push({
      name: LOGIN_COMP_NAME,
    });
  } else {
    console.log('Comp was login');
  }
}

export {
  instance,
  baseURL
};
