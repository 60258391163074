import { instance as axios } from './config.js';

export default {
  // Auth
  loginApi: data => axios({
    url: 'auth/login',
    method: 'POST',
    data,
  }),
  logoutApi: sid => axios({
    url: 'auth/logout',
    method: 'POST',
    data: { sid },
  }),
  registerApi: data => axios({
    url: 'auth/signup2',
    method: 'POST',
    data,
  }),
  postCheckEmail: data => axios({
    url: 'auth/check-email',
    method: 'POST',
    data,
  }),
  postMessage: data => axios({
    url: 'email/sendcontactemail',
    method: 'POST',
    data,
  }),
  // -- Refresh session
  expandSession: params => axios({
    url: 'auth/check-session',
    method: 'POST',
    params,
  }),
  setActiveClient: data => axios({
    url: 'auth/set-active-client',
    method: 'POST',
    data,
  }),

  // -- Profile
  getProfile: params => axios({
    url: 'auth/profile',
    params,
  }),
  patchProfile: data => axios({
    url: 'auth/profile',
    method: 'PATCH',
    data,
  }),
  patchConfig: data => axios({
    url: 'auth/config',
    method: 'PATCH',
    data,
  }),

  // Clients
  getOneClient: params => axios({
    url: `clients/${params.id}`,
    params,
  }),
  patchClient: data => axios({
    url: `clients/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getClients: params => axios({
    url: 'clients',
    params,
  }),
  postClients: data => axios({
    url: 'clients',
    method: 'POST',
    data,
  }),
  // Groups
  getOneGroup: params => axios({
    url: `groups/${params.id}`,
    params,
  }),
  patchGroup: data => axios({
    url: `groups/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getGroups: params => axios({
    url: 'groups',
    params,
  }),
  postGroups: data => axios({
    url: 'groups',
    method: 'POST',
    data,
  }),
  getCurrencies: params => axios({
    url: 'currencies',
    params,
  }),
  // Membership types
  getOneMembershipType: params => axios({
    url: `membershiptypes/${params.id}`,
    params,
  }),
  patchMembershipType: data => axios({
    url: `membershiptypes/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getMembershipTypes: params => axios({
    url: 'membershiptypes',
    params,
  }),
  postMembershipTypes: data => axios({
    url: 'membershiptypes',
    method: 'POST',
    data,
  }),
  // Memberships
  getOneMembership: params => axios({
    url: `memberships/${params.id}`,
    params,
  }),
  patchMembership: data => axios({
    url: `memberships/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getMemberships: params => axios({
    url: 'memberships',
    params,
  }),
  postMemberships: data => axios({
    url: 'memberships',
    method: 'POST',
    data,
  }),
  // Users
  getOneUser: params => axios({
    url: `users/${params.id}`,
    params,
  }),
  // ... similar to sign up API
  patchUser: data => axios({
    url: `users/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getUsers: params => axios({
    url: 'users',
    params,
  }),
  postUsers: data => axios({
    url: 'users',
    method: 'POST',
    data,
  }),
  // Locations
  getOneLocation: params => axios({
    url: `locations/${params.id}`,
    params,
  }),
  patchLocation: data => axios({
    url: `locations/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getLocations: params => axios({
    url: 'locations',
    params,
  }),
  postLocations: data => axios({
    url: 'locations',
    method: 'POST',
    data,
  }),

  // Classes
  getOneClass: params => axios({
    url: `classes/${params.id}`,
    params,
  }),
  patchClass: data => axios({
    url: `classes/${data.id}`,
    method: 'PATCH',
    data,
  }),
  getClasses: params => axios({
    url: 'classes',
    params,
  }),
  getClassesInstructors: params => axios({
    url: 'classes/getInstructors',
    params,
  }),
  postClasses: data => axios({
    url: 'classes',
    method: 'POST',
    data,
  }),

  // Countries
  getCountries: params => axios({
    url: 'countries',
    params,
  }),
  getCities: params => axios({
    url: 'cities',
    params,
  }),
  // Client types
  getClientTypes: params => axios({
    url: 'clienttypes',
    params,
  }),
  // Roles
  getRoles: params => axios({
    url: 'roles',
    params,
  }),

  // Notification
  checkNotificationCount: params => axios({
    url: 'messages',
    params: {
      count: true,
    },
  }),
  getNotificationList: params => axios({
    url: 'messages',
    params,
  }),
  getOneNotification: params => axios({
    url: `messages/${params.id}`,
    params,
  }),
  postNotification: data => axios({
    url: 'messages',
    method: 'POST',
    data,
  }),
  patchNotification: data => axios({
    url: `messages/${data.id}`,
    method: 'PATCH',
    data,
  }),
  deleteNotification: params => axios({
    url: `messages/${params.id}`,
    method: 'DELETE',
    params,
  }),
};
