<template>
  <div class="pagination-wrap">
    <multiselect-view :handleProps="paginationProps" :handleEvents="paginationEvents"></multiselect-view>
    <pagination-view :current-page="routeQuery.page" :total-pages="pagination.totalPages" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: ['routeQuery', 'pagination'],
  data () {
    return {
      DEFAULT_PER_PAGE: 5,
      paginationOptions: [5, 10, 15, 20, 25, 30],
    };
  },
  mounted () {
    this.getPerPageData();
  },
  computed: {
    ...mapState({
      paginationOptionNumber: 'paginationOptionNumber',
    }),
    paginationProps () {
      return {
        options: this.paginationOptions,
        placeholder: '',
        title: this.$i18n.t('pagination.perPage'),
        value: this.paginationOptions.find(perPage => perPage === this.paginationOptionNumber),
        allowEmpty: false,
        searchable: true,
        // label: 'label'
      };
    },
    paginationEvents () {
      return {
        select: (evtNum) => {
          this.setPageNum(evtNum);
        },
        remove: () => {
          // This shouldn't happen
          this.setPaginationData(this.DEFAULT_PER_PAGE);
          const newQueryParams = { ...this.$route.query };
          delete newQueryParams.perPage;
          delete newQueryParams.page;
          this.$router.replace({ query: newQueryParams });
          this.$emit('per-page-change', this.paginationOptionNumber);
          this.setConfigPagination(this.DEFAULT_PER_PAGE);
        },
      };
    },
  },
  methods: {
    ...mapActions({
      setPaginationData: 'setPaginationData',
      patchConfig: 'patchConfig',
    }),
    getPerPageData () {
      const num = parseInt(this.$route.query.perPage, 10) || this.DEFAULT_PER_PAGE;
      this.setPaginationData(this.paginationOptions.find(perPage => perPage === num));

      // Don't invoke event programatically
      // this.setPageNum(num)
    },
    setPageNum (num) {
      this.setPaginationData(num);
      const newQueryParams = { ...this.$route.query, perPage: num };
      delete newQueryParams.page;
      this.$router.replace({ query: newQueryParams });
      this.$emit('per-page-change', this.paginationOptionNumber);
      this.setConfigPagination(num);
    },
    async setConfigPagination (num) {
      const params = { key: 'perPage', value: num };
      try {
        const res = await this.patchConfig(params);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 20px;
  margin: auto 0 20px auto;
  justify-content: center;

  .multiselect-view {
    width: 70px;

    @media screen and (min-width: 768px) {
      width: 100px;
    }
  }
}
</style>
