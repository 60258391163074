<template>
  <div id="register-page">
    <transition
      name="scale"
      @after-enter="afterEnterEvent"
    >
      <div
        class="form"
        v-if="isFormLoaded"
      >
        <div
          class="close-btn"
          v-if="!isSignUp"
          @click="routeGoBackCheck()"
        >
          <div class="fa fa-times"></div>
        </div>
        <!-- <router-link to="/" v-if="isSignUp">
          <div class="logo">
            <img src="@/assets/image/logo.png" alt="LOGO" draggable="false" />
          </div>
        </router-link>-->
        <div class="header">
          <span v-if="!parsedUserId">
            <template v-if="isSignUp">{{$t('auth.register')}}</template>
            <template v-if="!isSignUp">{{$tc('app.user.title',1)}}</template>
          </span>
          <span v-if="parsedUserId">{{$tc('app.user.title',1)}}</span>
        </div>
        <div class="center-el">
          <div class="left">
            <div class="input-group">
              <input
                class="alt"
                v-model="form.usr_first_name"
                :placeholder="placeholderTranslations.name"
                :title="placeholderTranslations.name"
                type="text"
                @keypress.enter="submitForm"
                required
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.usr_last_name"
                :placeholder="placeholderTranslations.lastname"
                :title="placeholderTranslations.lastname"
                type="text"
                @keypress.enter="submitForm"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.usr_phone"
                :placeholder="placeholderTranslations.phone"
                :title="placeholderTranslations.phone"
                type="text"
                @keypress.enter="submitForm"
              />
            </div>
            <div class="input-group">
              <datepicker-wrapper
                v-model="form.usr_birth_date"
                :placeholder="placeholderTranslations.birth_date"
                :max-datetime="getTomorrowDate"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.usr_email"
                :placeholder="placeholderTranslations.email"
                :title="placeholderTranslations.email"
                autocomplete="off"
                type="text"
                :required="isSignUp || !isUserBelowSelected"
                @keypress.enter="submitForm"
                @change="checkEmailExist"
              />
            </div>
            <div
              class="email-exist-text"
              v-if="emailExists"
            >{{emailExistText}}</div>
            <div
              class="input-group"
              v-if="canShowPassField"
            >
              <input
                class="alt"
                v-model="form.password"
                :placeholder="placeholderTranslations.password"
                :title="placeholderTranslations.password"
                autocomplete="new-password"
                type="password"
                :required="isSignUp || !isUserBelowSelected"
                @keypress.enter="submitForm"
              />
            </div>
          </div>
          <div class="right">
            <template v-if="!isSignUp">
              <div
                class="input-group"
                v-if="checkHasPermission('view-roles')"
              >
                <multiselect-view
                  :handleProps="rolesProps"
                  :handleEvents="rolesEvents"
                  :slot-text="multiSlotsMultiselect"
                  required
                />
              </div>
              <div class="input-group">
                <multiselect-view
                  :handleProps="groupsProps"
                  :handleEvents="groupsEvents"
                  :slot-text="multiSlotsMultiselect"
                />
              </div>
            </template>
            <div
              class="input-group"
              v-if="!isSignUp"
            >
              <multiselect-view
                :handleProps="genderProps"
                :handleEvents="genderEvents"
                :slot-text="multiSlotsMultiselect"
              />
            </div>
            <template v-if="isSignUp">
              <div class="input-group">
                <multiselect-view
                  :handleProps="countriesProps"
                  :handleEvents="countriesEvents"
                  :slot-text="multiSlotsMultiselect"
                  :required="isSignUp"
                />
              </div>
              <div class="input-group">
                <multiselect-view
                  :handleProps="clientTypesProps"
                  :handleEvents="clientTypesEvents"
                  :slot-text="multiSlotsMultiselect"
                  :required="isSignUp"
                />
              </div>
              <div class="input-group">
                <input
                  class="alt"
                  v-model="form.cln_name"
                  :placeholder="placeholderTranslations.cln_name"
                  :title="placeholderTranslations.cln_name"
                  type="text"
                  :required="isSignUp"
                  @keypress.enter="submitForm"
                />
              </div>
              <div class="input-group">
                <input
                  class="alt"
                  v-model="form.cln_description"
                  :placeholder="placeholderTranslations.cln_description"
                  :title="placeholderTranslations.cln_description"
                  type="text"
                  @keypress.enter="submitForm"
                />
              </div>
              <div class="input-group">
                <input
                  class="alt"
                  v-model="form.cln_phone"
                  :placeholder="placeholderTranslations.cln_phone"
                  :title="placeholderTranslations.cln_phone"
                  type="text"
                  @keypress.enter="submitForm"
                />
              </div>
              <div class="input-group">
                <input
                  class="alt"
                  v-model="form.cln_email"
                  :placeholder="placeholderTranslations.cln_email"
                  :title="placeholderTranslations.cln_email"
                  type="text"
                  @keypress.enter="submitForm"
                />
              </div>
            </template>
          </div>
        </div>
        <div class="text-mini">* {{$t('app.requiredFields')}}</div>
        <button
          class="acc"
          :disabled="!isFormFilled || isApiLoading"
          @click="submitForm"
        >
          <span :class="{'progress': isApiLoading}">{{getSaveBtnName}}</span>
        </button>
        <router-link
          to="/"
          class="btn"
          v-if="isSignUp"
        >
          <span>{{$t('app.cancelAlt')}}</span>
        </router-link>
        <router-link
          :to="{name: 'LoginPage'}"
          class="auth-link"
          v-if="isSignUp"
        >
          <span>{{$t('auth.alreadyHaveAccount')}}.</span>
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  name: 'RegisterPage',
  mixins: [formMixin],
  props: {
    user_id: [String, Number],
    group_id: [String, Number],
  },
  data () {
    return {
      isFormLoaded: false,
      parsedUserId: null,
      formBaseData: {},
      form: {
        // usr_first_name: null,
        // usr_last_name: null,
        // usr_phone: null,
        // usr_email: null,
        // usr_birth_date: null,
        // password: null,
        // group_ids: [],
        // cnt_id: null,
        // clt_id: null,
        // // cln_id: null,
        // cln_description: null,
        // cln_phone: null,
        // cln_email: null,
        // rls_id: null
      },
      isApiLoading: false,
      transformMap: {
        usr_id: 'id',
        usr_first_name: 'first_name',
        usr_last_name: 'last_name',
        usr_description: 'description',
        usr_email: 'email',
        usr_phone: 'phone',
      },
      groups: [],
      countries: [],
      clientTypes: [],
      rolesOptions: [],
      emailExists: false,
    };
  },
  computed: {
    isSignUp () {
      return !this.$route.params.client_id;
    },
    isUserBelowSelected () {
      // const roleMember = 100
      const roleInstructor = 300;
      return this.form.rls_id <= roleInstructor;
    },
    getSaveBtnName () {
      if (this.parsedUserId) {
        return this.$i18n.t('app.save');
      } else if (!this.isSignUp) {
        return this.$i18n.t('app.add');
      }
      return this.$i18n.t('auth.register');
    },
    canShowPassField () {
      if (this.parsedUserId && !this.checkHasPermission('edit-other-password')) {
        return false;
      }
      return true;
    },
    isFormFilled () {
      return true;
      // return Object.values(this.form).every(fe => fe !== null)
    },
    genderOptions () {
      return [
        {
          usr_gender: 1,
          name: this.$i18n.t('app.male'),
        },
        {
          usr_gender: 2,
          name: this.$i18n.t('app.female'),
        },
        {
          usr_gender: 3,
          name: this.$i18n.t('app.other'),
        }
      ];
    },
    genderProps () {
      return {
        // __isFixedDrop: true,
        options: this.genderOptions,
        placeholder: this.placeholderTranslations.gender,
        title: this.placeholderTranslations.gender,
        value: this.genderOptions.find(item => item.usr_gender === this.form.usr_gender),
        searchable: true,
        label: 'name',
      };
    },
    genderEvents () {
      return {
        select: (evt) => this.$set(this.form, 'usr_gender', evt.usr_gender),
        remove: () => this.$set(this.form, 'usr_gender', null),
      };
    },
    groupsProps () {
      return {
        // __isFixedDrop: true,
        options: this.groups,
        placeholder: this.placeholderTranslations.groups,
        title: this.placeholderTranslations.groups,
        value: this.groups.filter(item => this.form.group_ids?.includes(item.grp_id)),
        searchable: true,
        multiple: true,
        label: 'grp_name',
      };
    },
    groupsEvents () {
      return {
        // input: (evt) => {
        //   // this.$set(this.form, 'group_ids', evt.map(item => item.grp_id))
        //   console.warn(evt)
        // }
        // select: (evt) => this.$set(this.form, 'group_ids', evt.grp_id),
        select: (evt) => {
          if (!this.form.group_ids) {
            this.$set(this.form, 'group_ids', []);
          }
          this.form.group_ids.push(evt.grp_id);
        },
        remove: (evt) => this.$set(this.form, 'group_ids', this.form.group_ids?.filter(id => id !== evt.grp_id)),
      };
    },
    countriesProps () {
      return {
        // __isFixedDrop: true,
        options: this.countries,
        placeholder: this.placeholderTranslations.country,
        title: this.placeholderTranslations.country,
        value: this.countries.find(item => item.cnt_id === this.form.cnt_id),
        searchable: true,
        label: 'cnt_name',
      };
    },
    countriesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'cnt_id', evt.cnt_id),
        remove: () => this.$set(this.form, 'cnt_id', null),
      };
    },
    clientTypesProps () {
      return {
        // __isFixedDrop: true,
        options: this.clientTypes,
        placeholder: this.placeholderTranslations.clientType,
        title: this.placeholderTranslations.clientType,
        value: this.clientTypes.find(item => item.clt_id === this.form.clt_id),
        searchable: true,
        label: 'clt_name',
      };
    },
    clientTypesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'clt_id', evt.clt_id),
        remove: () => this.$set(this.form, 'clt_id', null),
      };
    },
    rolesProps () {
      return {
        // __isFixedDrop: true,
        options: this.rolesOptions,
        placeholder: this.placeholderTranslations.member,
        title: this.placeholderTranslations.member,
        value: this.rolesOptions.find(item => item.rls_id === this.form.rls_id),
        searchable: true,
        label: 'rls_name',
      };
    },
    rolesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'rls_id', evt.rls_id),
        remove: () => this.$set(this.form, 'rls_id', null),
      };
    },
    emailExistText () {
      return `${this.$i18n.t('auth.emailExist')}.`;
    },
  },
  watch: {
    langChangeEventCo () {
      this.fetchDropdownData();
    },
    isUserBelowSelected () {
      this.$nextTick(() => {
        this.formManageInputPlaceholder(false);
      });
    },
  },
  mounted () {
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    if (!this.isSignUp) {
      // Preset value to member
      const memberRole = 100;
      this.$set(this.form, 'rls_id', memberRole);
    }
    this.checkGetUserId();
    if (this.group_id) {
      const parsedGroupIdArr = [parseInt(this.group_id, 10)] || [];
      this.$set(this.form, 'group_ids', parsedGroupIdArr);
    }
    // this.setupClientId()
    this.fetchDropdownData();
  },
  beforeDestroy () {
    this.isFormLoaded = false;
  },
  methods: {
    ...mapActions({
      getGroups: 'getGroups',
      getCountries: 'getCountries',
      getClientTypes: 'getClientTypes',
      getRoles: 'getRoles',
      postRegister: 'postRegister',
      getOneUser: 'getOneUser',
      patchUser: 'patchUser',
      postUsers: 'postUsers',
      postCheckEmail: 'postCheckEmail',
    }),
    afterEnterEvent (evt) {
      this.$emit('after-enter', evt);
      this.formManageInputPlaceholder();
    },
    async fetchDropdownData () {
      if (this.isSignUp) {
        // Client data
        await this.fetchCountries();
        await this.fetchClientTypes();
      } else {
        // User data
        await this.fetchRoles();
        await this.fetchGroups();
      }
      this.$nextTick(() => (this.isFormLoaded = true));
    },
    async fetchCountries () {
      try {
        const res = await this.getCountries({});
        this.countries = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchClientTypes () {
      try {
        const res = await this.getClientTypes({});
        this.clientTypes = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchRoles () {
      try {
        const res = await this.getRoles({});
        this.rolesOptions = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchGroups () {
      const params = {
        grp_active: 1,
      };
      try {
        const res = await this.getGroups(params);
        this.groups = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async checkEmailExist () {
      if (this.isSignUp) {
        // No check on signup
        return;
      }
      this.isApiLoading = true;

      const params = {
        usr_email: this.form.usr_email,
      };

      const emailRegexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regexTest = new RegExp(emailRegexPattern, 'g').test(params.usr_email);
      if (regexTest) {
        try {
          const res = await this.postCheckEmail(params);
          if (res.data.data?.length) {
            // Email exists - reuse
            this.emailExists = true;
            const firstUser = res.data.data[0];
            this.$set(this.form, 'usr_id', firstUser.usr_id);
          } else {
            this.emailExists = false;
            this.$delete(this.form, 'usr_id');
          }
        } catch (err) {
          this.errorHelperMixin.parsePrintErrorMsg(err);
        }
      }
      this.isApiLoading = false;
    },
    async submitForm () {
      this.isApiLoading = true;
      if (this.parsedUserId) {
        await this.updateUser();
      } else {
        if (this.isSignUp) {
          await this.registerForm();
        } else {
          await this.saveUser();
        }
      }
      this.isApiLoading = false;
    },
    async registerForm () {
      const params = this.formatParams(this.form);
      try {
        const res = await this.postRegister(params);
        this.handleSuccessRes(res.data.message);
        this.$router.push({
          name: 'LoginPage',
        });
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    formatParams (form) {
      if (!form.usr_email) {
        // Remove the email so it doesn't trigger the backend validation
        delete form.usr_email;
      }
      return form;
    },
    async saveUser () {
      const params = this.formatParams(this.form);
      try {
        const res = await this.postUsers(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async updateUser () {
      const params = {
        id: this.parsedUserId,
        ...this.getObjectDiffVals(this.form, this.formBaseData),
      };
      try {
        const res = await this.patchUser(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('user params:', this.form);
    },
    checkGetUserId () {
      this.parsedUserId = parseInt(this.$route.params.user_id, 10) || null;
      if (this.parsedUserId) {
        this.fetchUser();
      }
    },
    async fetchUser () {
      try {
        const res = await this.getOneUser({ id: this.parsedUserId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('user params:', this.form);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      if (!this.isSignUp) {
        // If not sign up, return to previous page
        this.routeGoBackCheck();
      }
    },
    // setupClientId () {
    //   this.$set(this.form, 'cln_name', '')
    // }
  },
};
</script>

<style lang="scss" scoped>
#register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px;
  flex: 1;

  .form {
    @include forms;

    .email-exist-text {
      color: $global-text;
      cursor: default;
    }

    a {
      text-decoration: none;
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: transform 0.2s ease;
  }

  .scale-enter,
  .scale-leave-to {
    transform: scale(0.8);
  }
}
</style>
