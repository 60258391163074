<template>
  <div id="group-table-list">
    <div class="top-head">
      <div class="left">
        <router-link
          :to="{ name: 'ClientTableListAll' }"
          class="btn"
          v-if="checkHasPermission('') && canGoBack"
        >
          <!-- view-clients -->
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>
        <span>{{ $tc('app.group.title', 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="activeStatusSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{ name: 'GroupAdd', params: { client_id: clientData.id } }"
          v-if="checkHasPermission('create-groups')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t('app.group.addNew') }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="groupsData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @toggle-activation="toggleActiveState"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'GroupTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  data () {
    return {
      groupsData: [],
      tableShowFunc: false,
      activeStatusSelection: true,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        grp_id: 'id',
        grp_name: 'name',
        grp_description: 'description',
        grp_email: 'email',
        grp_phone: 'phone',
        grp_active: '_isActiveToggle',
      },

    };
  },
  computed: {
    canGoBack () {
      return this.$route.name !== 'GroupTableListAll';
    },
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'description',
          label: this.$i18n.t('app.description'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'lct_name',
          label: this.$i18n.tc('app.location.title', 1),
          style: {
            width: '200px',
          },
        },
        {
          name: 'grp_registration_date',
          type: 'date',
          format: '',
          label: this.$i18n.t('app.registrationDate'),
          style: {
            width: '150px',
          },
        },
        {
          name: 'activate',
          label: this.$i18n.t('selection.activeStatus'),
          permissions: 'activate-deactivate-groups',
          isMobileHeader: true,
          slots: {
            activate: true,
          },
        },
        {
          name: 'classes',
          label: '',
          permissions: 'view-classes',
          style: {
            width: '150px',
          },
          slots: {
            classes: true,
          },
        },
        {
          name: 'edit-group',
          label: '',
          permissions: 'edit-groups',
          style: {
            width: '150px',
          },
          slots: {
            editGroup: true,
          },
        }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-groups'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        activeStatus: this.checkHasPermission('activate-deactivate-groups'),
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchGroupsList();
    },
  },
  mounted () {
    this.routeQuery = { ...this.routeQuery, ...this.queryStringToInt(this.$route.query, this.keysTransform) };
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchGroupsList();
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getGroups: 'getGroups',
      patchGroup: 'patchGroup',
    }),
    async fetchGroupsList () {
      const params = {
        grp_active: this.activeStatusSelection,
        // grp_active: this.$route.query.active || 1,
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getGroups(params);
        const tempData = res.data.data;
        this.groupsData = tempData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchGroupsList();
    },
    async toggleActiveState (event) {
      // console.log('toggleActiveState', evt);
      const params = {
        id: event.row.id,
        grp_active: event.evt.checked,
      };
      try {
        const res = await this.patchGroup(params);
        this.showSuccessMsg(res.data.message);
        this.fetchGroupsList();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async rowSelect (rowEvt) {
      this.$router.push({
        name: 'UserTableListGroups',
        params: {
          client_id: this.$route.params.client_id,
          group_id: rowEvt.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#group-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
