
<div id="class-add">
  <div class="form" @input="inputCapture">
    <div class="close-btn" @click="routeGoBackCheck">
      <div class="fa fa-times"></div>
    </div>
    <div class="header">
      <span>{{ $tc('app.class.title', 1) }}</span>
    </div>
    <div class="input-group">
      <datepicker-wrapper
        input-class="accent"
        v-model="form.cls_date"
        :placeholder="placeholderTranslations.date"
        required
      />
    </div>
    <div class="input-group">
      <textarea
        class="alt max-w"
        v-model="form.cls_description"
        :placeholder="placeholderTranslations.description"
        :title="placeholderTranslations.description"
        type="text"
      />
    </div>
    <!-- <v-popover ref="popoverClassUsers"> -->
    <div class="input-group">
      <multiselect-view
        :handleProps="usersProps"
        :handleEvents="usersEvents"
        :slot-text="multiSlotsMultiselect"
        :title="placeholderTranslations.user"
        @click.stop.native
        required
      />
    </div>
    <transition name="scale">
      <div class="error-text" v-if="showUserErrorMsg">
        {{ $t('app.class.mustHaveOneUser') }}
      </div>
    </transition>
    <div class="input-group">
      <multiselect-view
        :handleProps="usersInstructorProps"
        :handleEvents="usersEvents"
        :slot-text="multiSlotsMultiselect"
        :title="placeholderTranslations.user"
        @click.stop.native
      />
    </div>
    <!-- <template slot="popover">
        <div class="popover-action-slot">
          <span>{{$t('app.confirmProceed')}}?</span>
          <div class="action">
            <button class="acc" @click="manageClass">
              <span>
                <span>{{$t('app.confirm')}}</span>
              </span>
            </button>
            <button @click.stop.prevent v-close-popover>
              <span>
                <span>{{$t('app.cancel')}}</span>
              </span>
            </button>
          </div>
        </div>
    </template>-->
    <!-- </v-popover> -->

    <div class="text-mini">* {{ $t('app.requiredFields') }}</div>
    <button
      class="acc long"
      :disabled="isApiLoading"
      @click="checkManageClass"
    >
      <span :class="{ progress: isApiLoading }">
        <i class="fa fa-save"></i>
        <span>{{ $t('app.save') }}</span>
      </span>
    </button>
  </div>
</div>
