export default {
  computed: {
    langSelectedForForms () {
      return this.$store.state.appLangSelected?.lang;
    },
  },
  watch: {
    langSelectedForForms () {
      this.$nextTick(() => {
        // Dom manipulation
        this.formManageInputPlaceholder(false);
      });
    },
  },
  mounted () {
    this.formManageInputPlaceholder();
  },
  methods: {
    formManageInputPlaceholder (canRemove = true) {
      const checkShouldDisplayPlaceholder = (inputEvt) => {
        const inputGroups = [...this.$el.querySelectorAll('.input-group')];
        // const parentEl = inputEvt.target.closest('.input-group')

        const createReqStar = (labelEl) => {
          const spanStar = document.createElement('span');
          spanStar.textContent = '*';
          spanStar.classList.add('red-star');
          labelEl.appendChild(spanStar);
        };

        const manageLabelEl = (parentEl, inputAreaEl, hasRequred) => {
          const placeholderText = inputAreaEl?.placeholder;
          const labelChild = parentEl.querySelector('label');
          if (!labelChild) {
            // Create
            const labelEl = document.createElement('label');
            labelEl.textContent = placeholderText;
            labelEl.addEventListener('click', () => inputAreaEl.focus());
            if (hasRequred) {
              createReqStar(labelEl);
            }
            parentEl.insertBefore(labelEl, parentEl.childNodes[0]);
          } else if (labelChild && canRemove) {
            // Remove
            parentEl.removeChild(labelChild);
          } else {
            // Update
            labelChild.textContent = placeholderText;
            labelChild.addEventListener('click', () => inputAreaEl.focus());
            if (hasRequred) {
              createReqStar(labelChild);
            }
          }
        };

        for (const iGroup of inputGroups) {
          const inputAreaEl = iGroup.querySelector('input, textarea');
          const hasRequred = iGroup.querySelector('[required]');
          manageLabelEl(iGroup, inputAreaEl, hasRequred);
        }
      };

      checkShouldDisplayPlaceholder();

      // const inputs = [...this.$el.querySelectorAll('input, textarea')]
      // if (inputs.length > 0) {
      //   for (const el of inputs) {
      // console.warn(el, inputs);
      // TEST ALL EVENTS
      // for (const testKey in el) {
      //   if (testKey.startsWith('on')) {
      //     el.addEventListener(testKey, checkShouldDisplayPlaceholder)
      //   }
      // }
      //     el.addEventListener('input', checkShouldDisplayPlaceholder)
      //   }
      // }
    },
  },
};
