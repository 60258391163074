import errorHelper from '@/assets/js/errorHelper.js';
import dayjs from 'dayjs';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { mapActions, mapGetters, mapState } from 'vuex';
import meta from '../../../public/metadata.json';

function convertStringToInt (inputObject, keysTransform) {
  // It will preserve other types
  const arrayKv = Object.entries(inputObject).map(([key, val]) => {
    if (typeof val === 'string' && keysTransform.includes(key)) {
      return [key, parseInt(val, 10)];
    }
    return [key, val];
  });

  const tempObj = {};
  for (const [key, val] of arrayKv) {
    tempObj[key] = val;
  }
  return tempObj;
}

export default {
  data () {
    return {
      errorHelperMixin: errorHelper,
      previousRouteData: null,
    };
  },
  computed: {
    ...mapState({
      clientData: state => state.clientData,
      userData: state => state.userData,
      isMobileView: state => state.isMobileView,
      langChangeEventCo: state => state.appLangEventCo,
      getNotificationCount: state => state.notificationCount,
    }),
    ...mapGetters({
      checkHasPermission: 'checkHasPermission',
      isMultipleClients: 'isMultipleClients',
      manualLink: 'manualLink',
    }),
    getDynamicPreviousRoute () {
      return this.previousRouteData || { name: 'ClientTableListAll' };
    },
    multiSlotsMultiselect () {
      return {
        noOptions: this.$i18n.t('selection.slotText.noOptions'),
        noResult: this.$i18n.t('selection.slotText.noResults'),
      };
    },
    getTomorrowDate () {
      const today = new Date();
      // const tomorrow = new Date(today)
      // tomorrow.setDate(today.getDate() + 1)
      return `${today.toISOString()}`;
    },
    placeholderTranslations () {
      return {
        name: this.$i18n.t('auth.name'),
        description: this.$i18n.t('app.description'),
        location: this.$i18n.tc('app.location.title', 1),
        user: this.$i18n.tc('app.user.title', 1),
        users: this.$i18n.tc('app.user.title', 2),
        userMember: this.$i18n.tc('app.userMember.title', 1),
        userMembers: this.$i18n.tc('app.userMember.title', 2),
        instructors: this.$i18n.tc('app.instructor.title', 2),
        classType: this.$i18n.tc('app.class.title', 1),
        membershipType: this.$i18n.tc('app.membershipType.title', 1),
        roles: this.$i18n.tc('auth.role', 2),
        date: this.$i18n.t('app.date'),
        search: this.$i18n.t('selection.search'),
        selectUser: this.$i18n.t('selection.user'),
        member: this.$i18n.t('selection.member'),
        activeStatus: this.$i18n.t('selection.activeStatus'),
        clientType: this.$i18n.t('selection.clientType'),
        country: this.$i18n.t('selection.country'),
        paymentDate: this.$i18n.t('app.paymentDate'),
        expireDate: this.$i18n.t('app.expireDate'),
        price: this.$i18n.t('app.price'),
        message: this.$i18n.t('app.message'),
        clientDescription: this.$i18n.t('client.desc'),
        locationName: this.$i18n.t('app.location.name'),
        address: this.$i18n.t('app.address'),
        city: this.$i18n.t('app.city'),
        currency: this.$i18n.t('app.currency'),
        lastname: this.$i18n.t('auth.lastName'),
        email: this.$i18n.t('auth.email'),
        phone: this.$i18n.t('auth.phone'),
        birthDate: this.$i18n.t('auth.birthDate'),
        oldPassword: this.$i18n.t('auth.oldPassword'),
        newPassword: this.$i18n.t('auth.newPassword'),
        confirmPassword: this.$i18n.t('auth.confirmPassword'),
        password: this.$i18n.t('auth.password'),
        birth_date: `${this.$i18n.t('auth.birthDate')}`,
        groups: this.$i18n.t('selection.groups'),
        cln_name: this.$i18n.t('client.name'),
        cln_description: this.$i18n.t('client.desc'),
        cln_phone: this.$i18n.t('client.phone'),
        cln_email: this.$i18n.t('client.email'),
        gender: this.$i18n.t('app.gender'),
        lastMembershipDate: this.$i18n.t('app.lastMembershipDate'),
        messagesFilter: this.$i18n.t('app.messageFilter'),
        title: this.$i18n.t('app.title'),
      };
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRouteData = from;
    });
  },
  methods: {
    ...mapActions({
      setUserData: 'setUserData',
      updateAppVersionStore: 'updateAppVersionStore',
    }),
    getObjectFromKeys (inputObject, keys) {
      const tempObj = { ...inputObject };
      Object.keys(inputObject)
        .filter(key => !keys.includes(key))
        .forEach(key => delete tempObj[key]);
      return tempObj;
    },
    getObjectDiffVals (inputObject, inputBaseObject) {
      // Only for one level
      const newObj = {};
      for (const key of Object.keys(inputObject)) {
        const val = inputObject[key];
        const firstVal = JSON.stringify(val);
        const secondVal = JSON.stringify(inputBaseObject[key]);
        if (firstVal !== secondVal) {
          newObj[key] = val;
        }
      }
      return newObj;
    },
    transformApiDataObject (inputObject, transformMap, otherParams) {
      const inputKeys = transformMap && Object.keys(transformMap);
      if (!inputKeys || inputKeys.length === 0) {
        // console.log('[⚠️] - Empty transform map keys', transformMap);
        return inputObject;
      }
      const { isKeepOtherKeys = true, isKeepClone = false } = otherParams;

      const tempObj = {};
      for (const [key, val] of Object.entries(inputObject)) {
        const isTransInput = inputKeys.includes(key);
        if (isTransInput) {
          // Transform key properties
          const newKey = transformMap[key];
          tempObj[newKey] = val;
          if (isKeepClone) {
            tempObj[key] = val;
          }
        } else if (isKeepOtherKeys) {
          tempObj[key] = val;
        }
      }
      return tempObj;
    },
    queryStringToInt (inputObject, keysTransform = []) {
      return convertStringToInt(inputObject, keysTransform);
    },
    showSuccessMsg (msg) {
      Toastify({
        text: msg,
        duration: 3000,
        // destination: "",
        newWindow: true,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'right', // `left`, `center` or `right`
        // backgroundColor: "linear-gradient(90deg, rgba(59,179,53,1) 0%, rgba(55,157,49,1) 100%)",
        className: 'toastify-success',
        stopOnFocus: true, // Prevents dismissing of toast on hover
        onClick: function () { }, // Callback after click
      }).showToast();
    },
    async setupUserDataToStorage (sessionData) {
      if (sessionData.sid) {
        localStorage.sid = sessionData.sid;
      }
      const userData = sessionData.user;
      localStorage.user = JSON.stringify(userData);
      await this.setUserData(userData);
    },
    convertDateFromIsoToLocalAndReverse (
      dateInput,
      reverse = false,
      isCleanOnly = false,
      outputFormat = ''
    ) {
      let dateFormatStr = '';
      let dateInputFormatStr = '';
      const isoFormat = 'YYYY-MM-DD';
      const localFormat = 'DD.MM.YYYY';
      if (reverse) {
        if (!isCleanOnly) {
          dateInputFormatStr = localFormat;
        }
        dateFormatStr = isoFormat;
      } else {
        if (!isCleanOnly) {
          dateInputFormatStr = isoFormat;
        }
        dateFormatStr = localFormat;
      }
      if (outputFormat) {
        dateFormatStr = outputFormat;
      }
      return dayjs(dateInput, dateInputFormatStr).format(dateFormatStr);
    },
    checkOpenOutdatedAppModal (isNotify = false) {
      const verNet = this.$store.state.appFrontVersion;
      if (!verNet) {
        // If store wasn't updated
        return;
      }
      if (parseInt(localStorage.appBuildVersion, 10) === verNet) {
        // Ignore check
        return;
      }

      const verLoc = meta.build;
      if (verNet && !isNaN(verNet, 10) && verLoc < verNet) {
        const isProdApp = process.env.NODE_ENV === 'production';
        if (!isProdApp) {
          // Don't check on dev
          console.log('[⚠️] Back ver ahead', verLoc, verNet);
          clearInterval(this.intervalSes);
          return;
        }

        localStorage.appBuildVersion = verNet;
        if (isNotify) {
          this.openOldVersionPopup(verNet);
        } else {
          console.log('Updating app version', verNet);
          this.clearCacheAndReload();
        }
      } else if (verLoc > verNet) {
        console.log('[⚠️] Front ver ahead', verLoc, verNet);
        clearInterval(this.intervalSes);
      } else {
        // If version is same
        // clearInterval(this.intervalSes)
      }
    },
    openOldVersionPopup (verNet) {
      console.log('Showing toast app:', verNet);
      Toastify({
        text: this.$i18n.t('app.newVersion'),
        duration: -1,
        // close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        className: 'toastify-info',
        onClick: () => {
          this.clearCacheAndReload();
        },
      }).showToast();
    },
    resetPageFilter () {
      if (parseInt(this.$route.query.page, 10) !== 1) {
        if (this.routeQuery?.page) {
          this.routeQuery.page = 1;
        }
        this.$router.push({ query: { ...this.$route.query, page: 1 } });
      }
    },
    clearCacheAndReload () {
      if (this.$route.name !== 'LoginComp') {
        location.reload(true);
      }
    },
    routeGoBackCheck () {
      if (this.clientData.id) {
        // If client exists
        this.$router.go(-1);
      } else {
        this.$router.replace({
          name: 'ClientTableListAll',
        });
      }
    },
  },
};
