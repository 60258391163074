<template>
  <div id="location-table-list">
    <div class="top-head">
      <div class="left">
        <!-- <router-link
          :to="{name:'LocationTableList'}"
          v-if="checkHasPermission('view-locations')"
          class="btn"
        >
          <span>
            <i class="fa fa-chevron-left"></i>
            <span>{{ $t('app.back') }}</span>
          </span>
        </router-link>-->
        <span>{{ $tc('app.location.title', 2) }}</span>
        <span
          :title="$i18n.tc('app.client.title', 1)"
          v-if="isMultipleClients && clientData.name"
          >({{ clientData.name }})</span
        >
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="activeStatusSelected"
        />
      </div>
      <div class="right">
        <router-link
          :to="{ name: 'LocationAdd' }"
          v-if="checkHasPermission('create-locations')"
        >
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{ $t('app.location.addNew') }}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="locationsData"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @toggle-activation="toggleActiveState"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
        ></pagination-wrap>
      </div>
    </transition>
    <spinner-view
      :size="70"
      v-if="!tableShowFunc && !isLoadingData"
    ></spinner-view>
  </div>
</template>

<script>
import TableFilters from '@/components/Shared/table-filters/TableFilters.vue';
import TableWrapper from '@/components/Shared/table-wrap/TableWrapper.vue';
import { mapActions } from 'vuex';
export default {
  name: 'LocationTableList',
  components: {
    TableFilters,
    TableWrapper,
  },
  data () {
    return {
      locationsData: [],
      tableShowFunc: false,
      isLoadingData: true,
      citiesData: [],
      activeStatusSelection: true,
      pagination: {
        totalPages: 0,
      },
      routeQuery: {
        page: 1,
        search: '',
      },
      keysTransform: ['page'],
      transformMap: {
        lct_id: 'id',
        lct_name: 'name',
        lct_address: 'address',
        cit_name: 'city',
        lct_active: '_isActiveToggle',
      },
    };
  },
  computed: {
    columnsToShow () {
      return [
        {
          name: 'id',
          hidden: true,
        },
        {
          name: 'name',
          label: this.$i18n.t('auth.name'),
          style: {
            width: '200px',
          },
        },
        {
          name: 'address',
          label: this.$i18n.t('app.address'),
          style: {
            width: '200px',
          },
        },
        // {
        //   name: 'city',
        //   label: this.$i18n.t('app.city')
        // },
        {
          name: 'activate',
          label: this.$i18n.t('selection.activeStatus'),
          permissions: 'activate-deactivate-locations',
          isMobileHeader: true,
          slots: {
            activate: true,
          },
        }
      ];
    },
    tableOptions () {
      return {
        rowClasses: {
          selectable: this.checkHasPermission('edit-locations'),
        },
      };
    },
    tableFilterDisplay () {
      return {
        roles: false,
        // activeStatus: true,
        activeStatus: this.checkHasPermission('activate-deactivate-locations'),
      };
    },
  },
  watch: {
    $route (newValue, oldValue) {
      this.routeQuery = { ...this.queryStringToInt(this.$route.query, this.keysTransform) };
      this.fetchLocationsList();
    },
  },
  mounted () {
    this.$nextTick(() => (this.tableShowFunc = true));
    this.fetchData();
  },
  beforeDestroy () {
    this.$nextTick(() => (this.tableShowFunc = false));
  },
  methods: {
    ...mapActions({
      getLocations: 'getLocations',
      patchLocation: 'patchLocation',
      getCities: 'getCities',
    }),
    async fetchData () {
      // await this.fetchCities()
      await this.fetchLocationsList();
      this.isLoadingData = false;
    },
    async fetchCities () {
      try {
        const res = await this.getCities({});
        this.citiesData = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchLocationsList () {
      const params = {
        search: this.$route.query.search || null,
        page: this.$route.query.page || null,
        lct_active: this.activeStatusSelection,
        perPage: this.$route.query.perPage || 5,
      };
      try {
        const res = await this.getLocations(params);
        const tempData = res.data.data;
        const locationData = this.addAdditionalData(tempData);
        this.locationsData = locationData.map(responseObj => this.transformApiDataObject(responseObj, this.transformMap, { isKeepClone: false }));
        this.pagination.totalPages = res.data.meta?.pageCount || 0;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalData (arrData) {
      return arrData.map(data => {
        const cityFound = this.citiesData.find(city => city.cit_id === data.cit_id);
        return {
          ...data,
          cit_name: cityFound?.cit_name,
        };
      });
    },
    async rowSelect (rowEvt) {
      const params = {
        location_id: rowEvt.id,
      };
      this.$router.push({
        name: 'LocationEdit',
        params: {
          location_id: rowEvt.id,
        },
      });
    },
    activeStatusSelected (evt) {
      this.activeStatusSelection = evt;
      this.resetPageFilter();
      this.fetchLocationsList();
    },
    async toggleActiveState (event) {
      // console.log('toggleActiveState', evt);
      const params = {
        id: event.row.id,
        lct_active: event.evt.checked,
      };
      try {
        const res = await this.patchLocation(params);
        this.showSuccessMsg(res.data.message);
        this.fetchLocationsList();
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#location-table-list {
  @include table-outer;
  @include top-head-table;
  @include slide-left-active;
}
</style>
