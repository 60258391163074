
.multiselect-view(:class='multiClasses')
  multiselect(
    v-on='dynEvents',
    v-bind='dynProps',
    @open='vmsOpenEvt',
    @select='selectEventHandle',
    @input='inputEventHandle',
    ref='vms'
  )
    template(slot='singleLabel', slot-scope='props')
      slot(name='option-single', :props='props')
        span {{ getSlotTextSingle(props) }}
    template(slot='option', slot-scope='props')
      slot(name='option-pre', :props='props')
        span {{ getSlotTextPre(props) }}
    span(slot='noOptions', v-if='slotText.noOptions !== null') {{ slotText.noOptions }}
    span(slot='noResult', v-if='slotText.noResult !== null') {{ slotText.noResult }}
