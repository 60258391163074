
nav.pagination(v-if='totalPages')
  router-link(
    :to='{ query: { ...$route.query, page: 1 } }',
    @click.native='changePage(1)',
    tag='li'
  )
    span {{ $t("pagination.first") }}
  span.dots(v-if='currentPageNumber > Math.ceil(visiblePages / 2)') ...
  ul.pagination-list
    li(
      v-for='n in paginationRange',
      :class='{ "is-current": activePage(n) }',
      @click.prevent='completePageChange(n)'
    )
      a.pagination-link {{ n }}
  span.dots(
    v-if='currentPageNumber < totalPages - Math.floor(visiblePages / 2)'
  ) ...
  router-link(
    :to='{ query: { ...$route.query, page: lastPage } }',
    @click.native='changePage(lastPage)',
    tag='li'
  )
    span {{ $t("pagination.last") }}
