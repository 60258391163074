
<div id="class-table-list">
  <div class="top-head">
    <div class="left">
      <router-link
        :to="{ name: 'GroupTableListAll', params: { ...$route.params } }"
        v-if="checkHasPermission('view-groups') && canGoBack"
        class="btn"
      >
        <span>
          <i class="fa fa-chevron-left"></i>
          <span>{{ $t("app.back") }}</span>
        </span>
      </router-link>
      <router-link
        :to="getDynamicPreviousRoute"
        class="btn"
        v-else-if="canGoBack"
      >
        <span>
          <i class="fa fa-chevron-left"></i>
          <span>{{ $t("app.back") }}</span>
        </span>
      </router-link>
      <span>{{ $tc("app.class.title", 2) }}</span>
      <span
        :title="$i18n.tc('app.client.title', 1)"
        v-if="isMultipleClients && clientData.name"
        >({{ clientData.name }})</span
      >
      <span
        :title="$i18n.tc('app.group.title', 1)"
        v-if="checkHasPermission('view-groups') && canGoBack && groupDataName"
        >- ({{ groupDataName }})</span
      >
    </div>
    <div class="center">
      <!-- <table-filters
        :values="routeQuery"
        :display="tableFilterDisplay"
        @search-input="routeQuery.search = $event.target.value"
        @active-status-select="activeStatusSelected"
      /> -->
    </div>
    <div class="right">
      <router-link
        :to="{ name: 'ClassAdd' }"
        v-if="checkHasPermission('create-classes')"
      >
        <button class="acc">
          <span>
            <i class="fa fa-plus"></i>
            <span>{{ $t("app.class.addNew") }}</span>
          </span>
        </button>
      </router-link>
    </div>
  </div>
  <transition name="slide-left">
    <div class="table-wrap" v-if="tableShowFunc">
      <table-wrapper
        class="table"
        :rows="classesData"
        :columns="columnsToShow"
        :options="tableOptions"
        @row-select="rowSelect"
      ></table-wrapper>
      <pagination-wrap
        :route-query="routeQuery"
        :pagination="pagination"
      ></pagination-wrap>
    </div>
  </transition>
</div>
