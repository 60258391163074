<template>
  <div>
    <template v-if="isFullTable">
      <table-list
        class="table"
        :rows="rows"
        :columns="columns"
        :options="options"
        @row-select="$emit('row-select',$event)"
        @click-remove="$emit('click-remove',$event)"
        @toggle-activation="$emit('toggle-activation',$event)"
      >
        <slot></slot>
      </table-list>
    </template>
    <template v-else>
      <table-mobile-list
        class="table"
        :rows="rows"
        :columns="columns"
        :options="options"
        @row-select="$emit('row-select',$event)"
        @click-remove="$emit('click-remove',$event)"
        @toggle-activation="$emit('toggle-activation',$event)"
      >
        <slot></slot>
      </table-mobile-list>
    </template>
  </div>
</template>

<script>
import TableList from '@/components/Shared/table/TableList.vue';
import TableMobileList from '@/components/Shared/table/TableMobileList.vue';
export default {
  components: {
    TableList,
    TableMobileList,
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      default: () => ([]),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      isFullTable: true,
    };
  },
  watch: {
    isMobileView () {
      this.setupMobTable();
    },
  },
  mounted () {
    this.setupMobTable();
  },
  methods: {
    setupMobTable () {
      if (this.isMobileView) {
        if (this.isFullTable) {
          this.isFullTable = false;
        }
      } else {
        if (!this.isFullTable) {
          this.isFullTable = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  overflow: auto;
}
</style>
