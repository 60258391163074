<template>
  <div id="client-add">
    <div class="form" @input="inputCapture">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{$tc('app.client.title',1)}}</span>
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model.trim="form.cln_name"
          :placeholder="placeholderTranslations.name"
          :title="placeholderTranslations.name"
          type="text"
          required
        />
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="countriesProps"
          :handleEvents="countriesEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="clientTypesProps"
          :handleEvents="clientTypesEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group">
        <textarea
          class="alt max-w"
          v-model.trim="form.cln_description"
          :placeholder="placeholderTranslations.clientDescription"
          :title="placeholderTranslations.clientDescription"
          type="text"
        />
      </div>
      <div class="text-mini">* {{$t('app.requiredFields')}}</div>
      <button class="acc long" :disabled="isApiLoading" @click="manageClient">
        <span :class="{'progress': isApiLoading}">
          <i class="fa fa-save"></i>
          <span>{{$t('app.save')}}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions, mapState } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      clientId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      countries: [],
      clientTypes: [],
      transformMap: {
        cln_id: 'id',
      },
      includeKeys: [
        'id',
        'cln_id',
        'clt_id',
        'cnt_id',
        'cln_name',
        'cln_description'
      ],
    };
  },
  computed: {
    countriesProps () {
      return {
        // __isFixedDrop: true,
        options: this.countries,
        placeholder: this.placeholderTranslations.country,
        title: this.placeholderTranslations.country,
        value: this.countries.find(item => item.cnt_id === this.form.cnt_id),
        searchable: true,
        label: 'cnt_name',
      };
    },
    countriesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'cnt_id', evt.cnt_id),
        remove: () => this.$set(this.form, 'cnt_id', null),
      };
    },
    clientTypesProps () {
      return {
        // __isFixedDrop: true,
        options: this.clientTypes,
        placeholder: this.placeholderTranslations.clientType,
        title: this.placeholderTranslations.clientType,
        value: this.clientTypes.find(item => item.clt_id === this.form.clt_id),
        searchable: true,
        label: 'clt_name',
      };
    },
    clientTypesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'clt_id', evt.clt_id),
        remove: () => this.$set(this.form, 'clt_id', null),
      };
    },
    fullUserName () {
      const firstName = this.form.usr_first_name_member || '';
      const lastName = this.form.usr_last_name_member || '';
      return `${firstName} ${lastName}`;
    },
  },
  watch: {
    langChangeEventCo () {
      this.fetchCountries();
      this.fetchClientTypes();
    },
  },
  mounted () {
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetClientId();
    this.fetchCountries();
    this.fetchClientTypes();
  },
  methods: {
    ...mapActions({
      getClientTypes: 'getClientTypes',
      getOneClient: 'getOneClient',
      postClients: 'postClients',
      patchClient: 'patchClient',
      getCountries: 'getCountries',
    }),
    checkGetClientId () {
      this.clientId = parseInt(this.$route.params.client_id, 10) || null;
      if (this.clientId) {
        this.fetchClientData();
      }
    },
    async fetchClientData () {
      try {
        const res = await this.getOneClient({ id: this.clientId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('client params:', this.form);
    },
    async fetchCountries () {
      try {
        const res = await this.getCountries({});
        this.countries = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchClientTypes () {
      try {
        const res = await this.getClientTypes({});
        this.clientTypes = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async manageClient () {
      this.isApiLoading = true;
      if (this.clientId) {
        await this.editClient();
      } else {
        await this.saveClient();
      }
      this.isApiLoading = false;
    },
    async editClient () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchClient(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('client params:', this.client);
    },
    async saveClient () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postClients(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('client params:', this.client);
    },
    inputCapture (evt) {
      console.log('input', evt);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#client-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }
}
</style>
