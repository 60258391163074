import Vue from 'vue';
import VueRouter from 'vue-router';
import permissionHelper from '../assets/js/permissionHelper.js';
import RegisterPage from '../views/Auth/Sub/RegisterPage.vue';
import SubSectionRoute from '../views/App/RoutesWraps/SubSectionRoute.vue';

// Client
import ClientTableList from '../views/App/Client/ClientTableList.vue';
import ClientAdd from '../views/App/Client/ClientAdd.vue';

// Group
import GroupTableList from '../views/App/Group/GroupTableList.vue';
import GroupAdd from '../views/App/Group/GroupAdd.vue';

// Membership
import MembershipTableList from '../views/App/Membership/MembershipTableList.vue';
import MembershipAdd from '../views/App/Membership/MembershipAdd.vue';

// Membership types
import MembershipTypesTableList from '../views/App/MembershipTypes/MembershipTypesTableList.vue';
import MembershipTypesAdd from '../views/App/MembershipTypes/MembershipTypesAdd.vue';

// Location
import LocationTableList from '../views/App/Location/LocationTableList.vue';
import LocationAdd from '../views/App/Location/LocationAdd.vue';

// Class
import ClassTableList from '../views/App/Class/ClassTableList.vue';
import ClassAdd from '../views/App/Class/ClassAdd.vue';

// User
import UserTableList from '../views/App/User/UserTableList.vue';

// Profile
import ProfileView from '../views/App/Profile/ProfileView.vue';
import { baseURL } from '../api/config.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/en',
  },
  {
    path: '/:lang',
    component: SubSectionRoute,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: () =>
          import(
            /* webpackChunkName: "homepage" */ '../views/Homepage/HomePage.vue'
          ),
      },
      {
        path: 'auth',
        name: 'AuthPage',
        component: () =>
          import(/* webpackChunkName: "auth" */ '../views/Auth/AuthPage.vue'),
        meta: { permissions: '' },
        children: [
          {
            path: 'login',
            name: 'LoginPage',
            component: () =>
              import(
                /* webpackChunkName: "auth" */ '../views/Auth/Sub/LoginPage.vue'
              ),
            meta: { permissions: '' },
          },
          {
            path: 'register',
            name: 'RegisterPage',
            component: RegisterPage,
            meta: { permissions: '' },
          },
        ],
      },
      {
        path: 'panel',
        // redirect: '/panel/clients',
        component: () =>
          import(/* webpackChunkName: 'panel' */ '../views/App/AppMain.vue'),
        meta: { needsSid: true },
        children: [
          {
            path: 'clients',
            alias: 'c',
            component: SubSectionRoute,
            children: [
              {
                path: '',
                name: 'ClientTableListAll',
                component: ClientTableList,
                // Not used - view-all-clients
                // Not used - view-clients
                meta: { permissions: '', isFocus: false },
              },
              {
                path: 'add',
                name: 'ClientAdd',
                component: ClientAdd,
                meta: { permissions: 'create-clients' },
              },
              {
                path: ':client_id/edit',
                name: 'ClientEdit',
                component: ClientAdd,
                meta: { permissions: 'edit-clients' },
              },
              // {
              //   path: ':client_id/groups',
              //   component: SubSectionRoute,
              //   children: [
              //     {
              //       path: '',
              //       name: 'GroupTableList',
              //       component: GroupTableList,
              //       meta: { permissions: 'view-groups', isFocus: false }
              //     },
              //     {
              //       path: ':group_id/users',
              //       name: 'UserTableList',
              //       component: UserTableList,
              //       meta: { permissions: 'view-users', isFocus: false }
              //     }
              //   ]
              // }
            ],
          },
          {
            path: 'c/:client_id',
            component: SubSectionRoute,
            children: [
              {
                path: 'groups',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'GroupTableListAll',
                    component: GroupTableList,
                    meta: { permissions: 'view-groups', isFocus: false },
                  },
                  {
                    path: 'add',
                    name: 'GroupAdd',
                    component: GroupAdd,
                    meta: { permissions: 'create-groups' },
                  },
                  {
                    path: ':group_id/edit',
                    name: 'GroupEdit',
                    component: GroupAdd,
                    meta: { permissions: 'edit-groups' },
                  },
                  {
                    path: ':group_id/users',
                    name: 'UserTableListGroups',
                    component: UserTableList,
                    meta: { permissions: 'view-users', isFocus: false },
                  },
                  {
                    path: ':group_id/classes',
                    component: SubSectionRoute,
                    props: true,
                    children: [
                      {
                        path: '',
                        name: 'ClassTableListAll',
                        component: ClassTableList,
                        meta: { permissions: 'view-classes', isFocus: false },
                      },
                      {
                        path: 'add',
                        name: 'ClassAdd',
                        component: ClassAdd,
                        meta: { permissions: 'create-classes' },
                      },
                      {
                        path: ':class_id/edit',
                        name: 'ClassEdit',
                        component: ClassAdd,
                        meta: { permissions: 'edit-classes' },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'memberships',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'MembershipTableListAll',
                    component: MembershipTableList,
                    meta: { permissions: 'view-memberships', isFocus: false },
                  },
                  {
                    path: 'user/:user_id',
                    name: 'MembershipTableUser',
                    component: MembershipTableList,
                    meta: { permissions: 'view-memberships', isFocus: false },
                  },
                  {
                    path: 'add',
                    name: 'MembershipAdd',
                    component: MembershipAdd,
                    props: true,
                    meta: { permissions: 'create-memberships' },
                  },
                  {
                    path: ':membership_id/edit',
                    name: 'MembershipEdit',
                    component: MembershipAdd,
                    props: true,
                    meta: { permissions: 'edit-memberships' },
                  },
                ],
              },
              {
                path: 'membershiptypes',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'MembershipTypesTableListAll',
                    component: MembershipTypesTableList,
                    meta: { permissions: 'view-membershiptypes', isFocus: false },
                  },
                  {
                    path: 'add',
                    name: 'MembershipTypesAdd',
                    component: MembershipTypesAdd,
                    meta: { permissions: 'create-membershiptypes' },
                  },
                  {
                    path: ':membership_type_id/edit',
                    name: 'MembershipTypesEdit',
                    component: MembershipTypesAdd,
                    props: true,
                    meta: { permissions: 'edit-membershiptypes' },
                  },
                ],
              },
              {
                path: 'locations',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'LocationTableListAll',
                    component: LocationTableList,
                    meta: { permissions: 'view-locations', isFocus: false },
                  },
                  {
                    path: 'add',
                    name: 'LocationAdd',
                    component: LocationAdd,
                    meta: { permissions: 'create-locations' },
                  },
                  {
                    path: ':location_id/edit',
                    name: 'LocationEdit',
                    component: LocationAdd,
                    props: true,
                    meta: { permissions: 'edit-locations' },
                  },
                ],
              },
              {
                path: 'users',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'UserTableListAll',
                    component: UserTableList,
                    meta: { permissions: 'view-users', isFocus: false },
                  },
                  {
                    path: 'add',
                    name: 'UserAdd',
                    component: RegisterPage,
                    props: true,
                    meta: { permissions: 'create-users' },
                  },
                  {
                    path: ':user_id/edit',
                    name: 'UserEdit',
                    component: RegisterPage,
                    props: true,
                    meta: { permissions: 'edit-users' },
                  },
                ],
              },
              {
                path: 'notifications',
                component: SubSectionRoute,
                children: [
                  {
                    path: '',
                    name: 'NotificationView',
                    component: () =>
                      import(/* webpackChunkName: 'notifications' */ '../views/App/Notification/NotificationView.vue'),
                    meta: { permissions: 'view-messages' },
                  },
                  {
                    path: 'add',
                    name: 'NotificationAdd',
                    component: () =>
                      import(/* webpackChunkName: 'notifications' */ '../views/App/Notification/NotificationAdd.vue'),
                    props: true,
                    meta: { permissions: 'create-messages' },
                  },
                  {
                    path: ':msg_id',
                    name: 'NotificationEdit',
                    component: () =>
                      import(/* webpackChunkName: 'notifications' */ '../views/App/Notification/NotificationAdd.vue'),
                    props: true,
                    // meta: { permissions: 'edit-messages' },
                    meta: { permissions: 'view-messages' },
                  },
                ],
              },
            ],
          },
          {
            path: 'profile',
            name: 'ProfileView',
            component: ProfileView,
          },
        ],
      },
    ],
  },
  {
    // Catch all
    path: '*',
    redirect: '/',
  },
];

const checkHasSess = async (to, from) => {
  const sid = localStorage.sid;
  if (sid) {
    if (!from.name) {
      const response = await fetch(`${baseURL}/auth/check-session?sid=${sid}`, {
        method: 'POST',
      });
      return response.status !== 401;
    }
    return true;
  }
  return false;
};

const router = new VueRouter({
  mode: 'history',
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
});

router.beforeEach(async (to, from, next) => {
  const matchedRountes = to.matched;
  if (matchedRountes.some(record => record.meta.needsSid)) {
    const hasValidSes = await checkHasSess(to, from);
    if (hasValidSes) {
      const firstBlockedPerm = matchedRountes.find(
        record => !permissionHelper.canAccessPermission(record.meta.permissions)
      );
      if (firstBlockedPerm) {
        console.error(
          '[🚪🔒] - No permission',
          firstBlockedPerm.meta.permissions
        );
        // Maybe change route in future
        next('/');
      } else {
        // const perms = matchedRountes
        //   .map(record => record.meta.permissions)
        //   .filter(Boolean)
        // if (perms.length) {
        //   console.log('[🚪🔑] - ', perms);
        // }
        next();
      }
    } else {
      // If session is invalidated
      console.log('[⚠️ - no SID]');
      next({
        name: 'LoginPage',
        params: to.params,
      });
    }
  } else {
    next();
  }
});

export default router;
