<template>
  <div class="mob-header">{{column.label}}</div>
</template>

<script>
export default {
  props: {
    column: Object,
    row: Object,
    cellData: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
