
<div id="register-page">
  <transition
    name="scale"
    @after-enter="afterEnterEvent"
  >
    <div
      class="form"
      v-if="isFormLoaded"
    >
      <div
        class="close-btn"
        v-if="!isSignUp"
        @click="routeGoBackCheck()"
      >
        <div class="fa fa-times"></div>
      </div>
      <!-- <router-link to="/" v-if="isSignUp">
        <div class="logo">
          <img src="@/assets/image/logo.png" alt="LOGO" draggable="false" />
        </div>
      </router-link>-->
      <div class="header">
        <span v-if="!parsedUserId">
          <template v-if="isSignUp">{{$t('auth.register')}}</template>
          <template v-if="!isSignUp">{{$tc('app.user.title',1)}}</template>
        </span>
        <span v-if="parsedUserId">{{$tc('app.user.title',1)}}</span>
      </div>
      <div class="center-el">
        <div class="left">
          <div class="input-group">
            <input
              class="alt"
              v-model="form.usr_first_name"
              :placeholder="placeholderTranslations.name"
              :title="placeholderTranslations.name"
              type="text"
              @keypress.enter="submitForm"
              required
            />
          </div>
          <div class="input-group">
            <input
              class="alt"
              v-model="form.usr_last_name"
              :placeholder="placeholderTranslations.lastname"
              :title="placeholderTranslations.lastname"
              type="text"
              @keypress.enter="submitForm"
            />
          </div>
          <div class="input-group">
            <input
              class="alt"
              v-model="form.usr_phone"
              :placeholder="placeholderTranslations.phone"
              :title="placeholderTranslations.phone"
              type="text"
              @keypress.enter="submitForm"
            />
          </div>
          <div class="input-group">
            <datepicker-wrapper
              v-model="form.usr_birth_date"
              :placeholder="placeholderTranslations.birth_date"
              :max-datetime="getTomorrowDate"
            />
          </div>
          <div class="input-group">
            <input
              class="alt"
              v-model="form.usr_email"
              :placeholder="placeholderTranslations.email"
              :title="placeholderTranslations.email"
              autocomplete="off"
              type="text"
              :required="isSignUp || !isUserBelowSelected"
              @keypress.enter="submitForm"
              @change="checkEmailExist"
            />
          </div>
          <div
            class="email-exist-text"
            v-if="emailExists"
          >{{emailExistText}}</div>
          <div
            class="input-group"
            v-if="canShowPassField"
          >
            <input
              class="alt"
              v-model="form.password"
              :placeholder="placeholderTranslations.password"
              :title="placeholderTranslations.password"
              autocomplete="new-password"
              type="password"
              :required="isSignUp || !isUserBelowSelected"
              @keypress.enter="submitForm"
            />
          </div>
        </div>
        <div class="right">
          <template v-if="!isSignUp">
            <div
              class="input-group"
              v-if="checkHasPermission('view-roles')"
            >
              <multiselect-view
                :handleProps="rolesProps"
                :handleEvents="rolesEvents"
                :slot-text="multiSlotsMultiselect"
                required
              />
            </div>
            <div class="input-group">
              <multiselect-view
                :handleProps="groupsProps"
                :handleEvents="groupsEvents"
                :slot-text="multiSlotsMultiselect"
              />
            </div>
          </template>
          <div
            class="input-group"
            v-if="!isSignUp"
          >
            <multiselect-view
              :handleProps="genderProps"
              :handleEvents="genderEvents"
              :slot-text="multiSlotsMultiselect"
            />
          </div>
          <template v-if="isSignUp">
            <div class="input-group">
              <multiselect-view
                :handleProps="countriesProps"
                :handleEvents="countriesEvents"
                :slot-text="multiSlotsMultiselect"
                :required="isSignUp"
              />
            </div>
            <div class="input-group">
              <multiselect-view
                :handleProps="clientTypesProps"
                :handleEvents="clientTypesEvents"
                :slot-text="multiSlotsMultiselect"
                :required="isSignUp"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.cln_name"
                :placeholder="placeholderTranslations.cln_name"
                :title="placeholderTranslations.cln_name"
                type="text"
                :required="isSignUp"
                @keypress.enter="submitForm"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.cln_description"
                :placeholder="placeholderTranslations.cln_description"
                :title="placeholderTranslations.cln_description"
                type="text"
                @keypress.enter="submitForm"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.cln_phone"
                :placeholder="placeholderTranslations.cln_phone"
                :title="placeholderTranslations.cln_phone"
                type="text"
                @keypress.enter="submitForm"
              />
            </div>
            <div class="input-group">
              <input
                class="alt"
                v-model="form.cln_email"
                :placeholder="placeholderTranslations.cln_email"
                :title="placeholderTranslations.cln_email"
                type="text"
                @keypress.enter="submitForm"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="text-mini">* {{$t('app.requiredFields')}}</div>
      <button
        class="acc"
        :disabled="!isFormFilled || isApiLoading"
        @click="submitForm"
      >
        <span :class="{'progress': isApiLoading}">{{getSaveBtnName}}</span>
      </button>
      <router-link
        to="/"
        class="btn"
        v-if="isSignUp"
      >
        <span>{{$t('app.cancelAlt')}}</span>
      </router-link>
      <router-link
        :to="{name: 'LoginPage'}"
        class="auth-link"
        v-if="isSignUp"
      >
        <span>{{$t('auth.alreadyHaveAccount')}}.</span>
      </router-link>
    </div>
  </transition>
</div>
