<template>
  <div id="location-add">
    <div class="form">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{ $tc('app.location.title', 1) }}</span>
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model="form.lct_name"
          :placeholder="placeholderTranslations.locationName"
          :title="placeholderTranslations.locationName"
          type="text"
          required
          :minlength="2"
          :maxlength="30"
        />
      </div>
      <div class="input-group">
        <input
          class="alt"
          v-model="form.lct_address"
          :placeholder="placeholderTranslations.address"
          :title="placeholderTranslations.address"
          type="text"
          required
          :minlength="2"
          :maxlength="30"
        />
      </div>
      <!-- <div class="input-group">
        <multiselect-view
          :handleProps="citiesProps"
          :handleEvents="citiesEvents"
          :slot-text="multiSlotsMultiselect"
          :title="placeholderTranslations.location"
        />
      </div>-->
      <div class="text-mini">* {{ $t('app.requiredFields') }}</div>
      <button class="acc" :disabled="isApiLoading" @click="manageLocation">
        <span :class="{ progress: isApiLoading }">
          <i class="fa fa-save"></i>
          <span>{{ $t('app.save') }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [formMixin],
  data () {
    return {
      locationId: null,
      form: {},
      formBaseData: {},
      isApiLoading: false,
      citiesData: [],
      transformMap: {
        lct_id: 'id',
      },
      includeKeys: [
        'id',
        'lct_name',
        'lct_address'
      ],
    };
  },
  computed: {
    citiesProps () {
      return {
        // __isFixedDrop: true,
        options: this.citiesData,
        placeholder: this.placeholderTranslations.city,
        value: this.citiesData.find(item => item.cit_id === this.form.cit_id),
        searchable: true,
        label: 'cit_name',
      };
    },
    citiesEvents () {
      return {
        select: (evt) => this.$set(this.form, 'cit_id', evt.cit_id),
        remove: () => this.$set(this.form, 'cit_id', null),
      };
    },
  },
  mounted () {
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetLocationId();
    // this.fetchCities()
  },
  methods: {
    ...mapActions({
      getOneLocation: 'getOneLocation',
      postLocations: 'postLocations',
      patchLocation: 'patchLocation',
      getCities: 'getCities',
    }),
    async fetchCities () {
      try {
        const res = await this.getCities({});
        this.citiesData = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchLocation () {
      const params = {
        id: this.locationId,
      };
      try {
        const res = await this.getOneLocation(params);
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    checkGetLocationId () {
      this.locationId = parseInt(this.$route.params.location_id, 10) || null;
      if (this.locationId) {
        this.fetchLocation();
      }
    },
    async manageLocation (evt) {
      const isValid = this.checkDomValidInputs();
      if (!isValid) {
        const msg = this.$i18n.t('app.someInputsInvalid');
        this.errorHelperMixin.parsePrintErrorMsg(msg);
        return;
      }

      this.isApiLoading = true;
      if (this.locationId) {
        await this.editLocation();
      } else {
        await this.saveLocation();
      }
      this.isApiLoading = false;
    },
    checkDomValidInputs (el = null) {
      // Checks all inputs in the comp for DOM validity
      let parentEl = this.$el;
      if (el) {
        parentEl = el;
      }

      const inputs = parentEl.querySelectorAll('input[required], textarea[required]');
      return [...inputs].every(inputEl => inputEl.checkValidity());
    },
    async editLocation () {
      const params = {
        grp_id: this.$route.params.group_id,
        ...this.getObjectFromKeys(this.form, this.includeKeys),
      };
      try {
        const res = await this.patchLocation(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('location params:', this.location);
    },
    async saveLocation () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postLocations(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('location params:', this.location);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#location-add {
  @include sub-page;

  .form {
    @include forms;
  }
}
</style>
