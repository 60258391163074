
<div id="client-table-list">
  <template>
    <div class="top-head">
      <div class="left">
        <!-- <span class="fa fa-arrow-left" @click="routeGoBackCheck()"></span> -->
        <span>{{$tc('app.client.title',2)}}</span>
      </div>
      <div class="center">
        <table-filters
          :values="routeQuery"
          :display="tableFilterDisplay"
          @search-input="routeQuery.search = $event.target.value"
          @active-status-select="$emit('active-status-select',$event)"
          @client-type-select="$emit('client-type-select',$event)"
        />
      </div>
      <div class="right">
        <router-link :to="{name: 'ClientAdd'}" v-if="checkHasPermission('create-clients')">
          <button class="acc">
            <span>
              <i class="fa fa-plus"></i>
              <span>{{$t('app.client.addNew')}}</span>
            </span>
          </button>
        </router-link>
      </div>
    </div>
    <transition name="slide-left">
      <div class="table-wrap" v-if="tableShowFunc">
        <table-wrapper
          class="table"
          :rows="clientsResponse.data"
          :columns="columnsToShow"
          :options="tableOptions"
          @row-select="rowSelect"
          @toggle-activation="$emit('toggle-activation', $event)"
        ></table-wrapper>
        <pagination-wrap
          :route-query="routeQuery"
          :pagination="pagination"
          @per-page-change="$emit('client-pagination-per-page',$event)"
        ></pagination-wrap>
      </div>
    </transition>
  </template>
</div>
