<template>
  <div id="membership-add">
    <div class="form" @input="inputCapture">
      <div class="close-btn" @click="routeGoBackCheck()">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{$tc('app.membership.title',1)}}</span>
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="usersProps"
          :handleEvents="usersEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <!-- <div class="input-group">
      <input
        class="alt"
        disabled
        :value="convertDateFromIsoToLocalAndReverse(selectedUser.max_mmb_date_to, null,null,'DD. MMM. YYYY')"
        :placeholder="placeholderTranslations.lastMembershipDate"
        :title="placeholderTranslations.lastMembershipDate"
      />
      </div>-->
      <div class="input-group">
        <datepicker-wrapper
          :value="selectedUser.max_mmb_date_to"
          :placeholder="placeholderTranslations.lastMembershipDate"
          :title="placeholderTranslations.lastMembershipDate"
          disabled
        />
      </div>
      <!-- <div class="input-group">
      <input
        class="alt"
        v-model.trim="fullUserName"
        disabled
        :title="placeholderTranslations.name"
        :placeholder="placeholderTranslations.name"
        type="text"
        v-if="fullUserName.trim()"
      />
      </div>-->
      <div class="input-group">
        <datepicker-wrapper
          v-model="form.mmb_payment_date"
          :placeholder="placeholderTranslations.paymentDate"
          :title="placeholderTranslations.paymentDate"
          required
        />
      </div>
      <div class="input-group">
        <datepicker-wrapper
          v-model="form.mmb_date_to"
          :placeholder="placeholderTranslations.expireDate"
          :title="placeholderTranslations.expireDate"
          required
        />
      </div>
      <div class="input-group">
        <multiselect-view
          :handleProps="membershipTypesProps"
          :handleEvents="membershipTypesEvents"
          :slot-text="multiSlotsMultiselect"
          required
        />
      </div>
      <div class="input-group">
        <input
          class="alt price"
          v-model="form.mmb_amount"
          :placeholder="placeholderTranslations.price"
          :title="placeholderTranslations.price"
          min="0"
          type="number"
          required
        />
      </div>
      <span v-if="displayCurrency">{{form.cur_code}}</span>
      <div class="input-group">
        <textarea
          class="alt max-w"
          v-model="form.mmb_description"
          :placeholder="placeholderTranslations.description"
          :title="placeholderTranslations.description"
          type="text"
          :required="isMmbDifferentMmtAmounts"
        />
      </div>
      <div class="text-mini">* {{$t('app.requiredFields')}}</div>
      <button class="acc long" :disabled="isApiLoading" @click="manageMembership">
        <span :class="{'progress': isApiLoading}">
          <i class="fa fa-save"></i>
          <span>{{$t('app.save')}}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  name: 'MembershipAdd',
  mixins: [formMixin],
  props: {
    user_id: [String, Number],
  },
  data () {
    return {
      membershipId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      displayCurrency: true,
      membershipTypes: [],
      usersData: [],
      transformMap: {
        mmb_id: 'id',
        // mmt_id: 'id',
        usr_id_member: 'usr_id',
      },
      includeKeys: [
        'id',
        'mmt_id',
        'usr_id',
        'mmb_payment_date',
        'mmb_date_to',
        'mmb_amount',
        'mmb_description'
      ],
    };
  },
  computed: {
    isMmbDifferentMmtAmounts () {
      if (this.form.mmt_id && this.form.mmb_amount) {
        const mmtFound = this.membershipTypes.find(mmt => mmt.mmt_id === this.form.mmt_id);
        if (mmtFound) {
          return parseFloat(mmtFound.mmt_amount, 10) !== parseFloat(this.form.mmb_amount, 10);
        }
      }
      return false;
    },
    membershipTypesProps () {
      return {
        // __isFixedDrop: true,
        options: this.membershipTypes,
        placeholder: this.placeholderTranslations.membershipType,
        title: this.placeholderTranslations.membershipType,
        value: this.membershipTypes.find(item => item.mmt_id === this.form.mmt_id),
        searchable: true,
        label: 'mmt_name',
      };
    },
    membershipTypesEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'mmt_id', evt.mmt_id);
          this.$set(this.form, 'mmb_amount', evt.mmt_amount);
          this.$set(this.form, 'cur_code', evt.cur_code);
          // this.$set(this.form, 'mmb_description', evt.mmb_description)
          this.$set(this.form, 'mmb_description', null);
        },
        remove: () => {
          this.$set(this.form, 'mmt_id', null);
          this.$delete(this.form, 'mmb_amount');
          this.$delete(this.form, 'cur_code');
        },
      };
    },
    usersProps () {
      return {
        // __isFixedDrop: true,
        options: this.usersData,
        placeholder: this.placeholderTranslations.selectUser,
        title: this.placeholderTranslations.selectUser,
        value: this.usersData.filter(item => this.form.usr_id === item.usr_id),
        searchable: true,
        // closeOnSelect: false,
        // multiple: true,
        label: 'usr_name',
      };
    },
    usersEvents () {
      return {
        select: (evt) => {
          this.$set(this.form, 'usr_id', evt.usr_id);
        },
        remove: (evt) => {
          this.$set(this.form, 'usr_id', null);
        },
      };
    },
    fullUserName () {
      const firstName = this.form.usr_first_name_member || '';
      const lastName = this.form.usr_last_name_member || '';
      return `${firstName} ${lastName}`;
    },
    selectedUser () {
      return this.usersData.find(user => user.usr_id === this.form.usr_id) || {};
    },
  },
  watch: {
    langChangeEventCo () {
      this.fetchMembershipTypes();
    },
    isMmbDifferentMmtAmounts () {
      this.$nextTick(() => {
        this.formManageInputPlaceholder(false);
      });
    },
  },
  mounted () {
    this.autoFillDate();
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetMembershipId();
    this.fetchMembershipTypes();
    this.fetchUsersList();

    if (this.user_id) {
      const parsedUserId = parseInt(this.user_id, 10) || null;
      this.$set(this.form, 'usr_id', parsedUserId);
    }
  },
  methods: {
    ...mapActions({
      getMembershipTypes: 'getMembershipTypes',
      getOneMembership: 'getOneMembership',
      postMemberships: 'postMemberships',
      patchMembership: 'patchMembership',
      getUsers: 'getUsers',
    }),
    checkGetMembershipId () {
      this.membershipId = parseInt(this.$route.params.membership_id, 10) || null;
      if (this.membershipId) {
        this.fetchMembership();
      }
    },
    async fetchMembership () {
      const params = {
        id: this.membershipId,
      };
      try {
        const res = await this.getOneMembership(params);
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.form);
    },
    async fetchUsersList () {
      const params = {
        usr_active: 1,
      };
      try {
        const res = await this.getUsers(params);
        const tempData = res.data.data.map(responseObj => this.transformApiDataObject(responseObj, {}, { isKeepClone: false }));
        this.usersData = this.addAdditionalData(tempData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalData (tempData) {
      function fullUserName (data) {
        const userId = data.usr_id || '';
        const firstName = data.usr_first_name || '';
        const lastName = data.usr_last_name || '';
        return `${userId} - ${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.usr_name = fullUserName(data);
      }

      return tempData;
    },
    async fetchMembershipTypes () {
      const params = {
        mmt_active: 1,
      };
      try {
        const res = await this.getMembershipTypes(params);
        this.membershipTypes = res.data.data;
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async manageMembership () {
      this.isApiLoading = true;
      if (this.membershipId) {
        await this.editMembership();
      } else {
        await this.saveMembership();
      }
      this.isApiLoading = false;
    },
    async editMembership () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchMembership(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.membership);
    },
    async saveMembership () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.postMemberships(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('membership params:', this.membership);
    },
    autoFillDate () {
      this.form.mmb_payment_date = new Date().toISOString().split('T')[0];
    },
    inputCapture (evt) {
      console.log('input', evt);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#membership-add {
  @include sub-page;

  .form {
    @include forms;

    .price {
      color: $accent-color;
    }
  }
}
</style>
