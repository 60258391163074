<template>
  <div id="class-add">
    <div class="form" @input="inputCapture">
      <div class="close-btn" @click="routeGoBackCheck">
        <div class="fa fa-times"></div>
      </div>
      <div class="header">
        <span>{{ $tc('app.class.title', 1) }}</span>
      </div>
      <div class="input-group">
        <datepicker-wrapper
          input-class="accent"
          v-model="form.cls_date"
          :placeholder="placeholderTranslations.date"
          required
        />
      </div>
      <div class="input-group">
        <textarea
          class="alt max-w"
          v-model="form.cls_description"
          :placeholder="placeholderTranslations.description"
          :title="placeholderTranslations.description"
          type="text"
        />
      </div>
      <!-- <v-popover ref="popoverClassUsers"> -->
      <div class="input-group">
        <multiselect-view
          :handleProps="usersProps"
          :handleEvents="usersEvents"
          :slot-text="multiSlotsMultiselect"
          :title="placeholderTranslations.user"
          @click.stop.native
          required
        />
      </div>
      <transition name="scale">
        <div class="error-text" v-if="showUserErrorMsg">
          {{ $t('app.class.mustHaveOneUser') }}
        </div>
      </transition>
      <div class="input-group">
        <multiselect-view
          :handleProps="usersInstructorProps"
          :handleEvents="usersEvents"
          :slot-text="multiSlotsMultiselect"
          :title="placeholderTranslations.user"
          @click.stop.native
        />
      </div>
      <!-- <template slot="popover">
          <div class="popover-action-slot">
            <span>{{$t('app.confirmProceed')}}?</span>
            <div class="action">
              <button class="acc" @click="manageClass">
                <span>
                  <span>{{$t('app.confirm')}}</span>
                </span>
              </button>
              <button @click.stop.prevent v-close-popover>
                <span>
                  <span>{{$t('app.cancel')}}</span>
                </span>
              </button>
            </div>
          </div>
      </template>-->
      <!-- </v-popover> -->

      <div class="text-mini">* {{ $t('app.requiredFields') }}</div>
      <button
        class="acc long"
        :disabled="isApiLoading"
        @click="checkManageClass"
      >
        <span :class="{ progress: isApiLoading }">
          <i class="fa fa-save"></i>
          <span>{{ $t('app.save') }}</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import formMixin from '@/assets/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  name: 'ClassManage',
  mixins: [formMixin],
  data () {
    return {
      classId: null,
      formBaseData: {},
      form: {},
      isApiLoading: false,
      classTypes: [],
      usersData: [],
      instructorsData: [],
      confirmedEmptyUserList: false,
      showUserErrorMsg: false,
      transformMap: {
        cls_id: 'id',
        users: 'user_ids',
      },
      includeKeys: [
        'id',
        'cls_id',
        'cls_date',
        'user_ids',
        'cls_description'
      ],
    };
  },
  computed: {
    usersProps () {
      return {
        // __isFixedDrop: true,
        options: this.multiselectGroupAll(this.usersData),
        placeholder: this.placeholderTranslations.userMembers,
        title: this.placeholderTranslations.userMembers,
        value: this.usersData.filter(item => this.form.user_ids?.includes(item.usr_id)),
        searchable: true,
        closeOnSelect: false,
        groupValues: 'sub',
        groupLabel: 'label',
        groupSelect: true,
        multiple: true,
        label: 'usr_name',
      };
    },
    usersEvents () {
      return {
        select: (evt) => {
          if (!this.form.user_ids) {
            this.$set(this.form, 'user_ids', []);
          }
          if (Array.isArray(evt)) {
            this.form.user_ids.push(...evt.map(el => el.usr_id));
          } else {
            this.form.user_ids.push(evt.usr_id);
          }
        },
        remove: (evt) => {
          if (Array.isArray(evt)) {
            this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => !evt.map(el => el.usr_id).includes(id)));
          } else {
            this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => id !== evt.usr_id));
          }
        },
      };
    },
    usersInstructorProps () {
      return {
        // __isFixedDrop: true,
        options: this.multiselectGroupAll(this.instructorsData),
        placeholder: this.placeholderTranslations.instructors,
        value: this.instructorsData.filter(item => this.form.user_ids?.includes(item.usr_id)),
        searchable: true,
        closeOnSelect: false,
        groupValues: 'sub',
        groupLabel: 'label',
        groupSelect: true,
        multiple: true,
        label: 'usr_name',
      };
    },
    // usersInstructorEvents () {
    //     return {
    //     select: (evt) => {
    //       if (!this.form.user_ids) {
    //         this.$set(this.form, 'user_ids', [])
    //       }
    //       if (Array.isArray(evt)) {
    //         this.form.user_ids.push(...evt.map(el => el.usr_id))
    //       } else {
    //         this.form.user_ids.push(evt.usr_id)
    //       }
    //     },
    //     remove: (evt) => {
    //       if (Array.isArray(evt)) {
    //         this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => !evt.map(el => el.usr_id).includes(id)))
    //       } else {
    //         this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => id !== evt.usr_id))
    //       }
    //     }
    //     select: (evt) => {
    //       if (!this.form.user_ids) {
    //         this.$set(this.form, 'user_ids', [])
    //       }
    //       this.form.user_ids.push(evt.usr_id)
    //     },
    //     remove: (evt) => this.$set(this.form, 'user_ids', this.form.user_ids.filter(id => id !== evt.usr_id))
    //   }
    // }
  },
  mounted () {
    this.autoFillDate();
    this.formBaseData = JSON.parse(JSON.stringify(this.form));
    this.checkGetClassId();
    this.fetchUsersList();
    this.fetchInstructorsList();
  },
  methods: {
    ...mapActions({
      getUsers: 'getUsers',
      getOneClass: 'getOneClass',
      postClasses: 'postClasses',
      patchClass: 'patchClass',
      getClassesInstructors: 'getClassesInstructors',
    }),
    checkGetClassId () {
      this.classId = parseInt(this.$route.params.class_id, 10) || null;
      if (this.classId) {
        this.fetchClass();
      }
    },
    async fetchUsersList () {
      const params = {
        grp_id: this.$route.params.group_id,
        role_ids: 100,
        usr_active: true,
      };
      try {
        const res = await this.getUsers(params);
        const tempData = res.data.data.map(responseObj => this.transformApiDataObject(responseObj, {}, { isKeepClone: false }));
        this.usersData = this.addAdditionalData(tempData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    async fetchInstructorsList () {
      const params = {
        // grp_id: this.$route.params.group_id,
        // role_ids: 300,
        // usr_active: true,
      };
      try {
        const res = await this.getClassesInstructors(params);
        const tempData = res.data.data.map(responseObj => this.transformApiDataObject(responseObj, {}, { isKeepClone: false }));
        this.instructorsData = this.addAdditionalData(tempData);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
    },
    addAdditionalData (tempData) {
      function fullUserName (data) {
        const userId = data.usr_id || '';
        const firstName = data.usr_first_name || '';
        const lastName = data.usr_last_name || '';
        return `${userId} - ${firstName} ${lastName}`;
      }

      for (const data of tempData) {
        data.usr_name = fullUserName(data);
      }

      return tempData;
    },
    async fetchClass () {
      try {
        const res = await this.getOneClass({ id: this.classId });
        this.form = this.transformApiDataObject(res.data.data[0], this.transformMap, { isKeepClone: true });
        this.formBaseData = JSON.parse(JSON.stringify(this.form));
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('class params:', this.form);
    },
    checkManageClass () {
      this.showUserErrorMsg = false;
      const checkAnyUserSelected = (ids = []) => {
        return this.usersData.filter(user => user.rls_id === 100).some(user => ids.includes(user.usr_id));
      };

      if (checkAnyUserSelected(this.form?.user_ids)) {
        this.manageClass();
      } else {
        // So it rerenders
        this.$nextTick(() => {
          this.showUserErrorMsg = true;
        });
      }

      // Old popup check
      // if (this.form?.user_ids?.length > 0) {
      //   this.manageClass()
      // } else {
      //   this.$refs.popoverClassUsers.show()
      // }
    },
    async manageClass () {
      // this.$refs.popoverClassUsers.hide()
      this.isApiLoading = true;
      if (this.classId) {
        await this.editClass();
      } else {
        await this.saveClass();
      }
      this.isApiLoading = false;
    },
    async editClass () {
      const params = { ...this.getObjectFromKeys(this.form, this.includeKeys) };
      try {
        const res = await this.patchClass(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('class params:', this.class);
    },
    async saveClass () {
      const params = {
        grp_id: this.$route.params.group_id,
        ...this.getObjectFromKeys(this.form, this.includeKeys),
      };
      try {
        const res = await this.postClasses(params);
        this.handleSuccessRes(res.data.message);
      } catch (err) {
        this.errorHelperMixin.parsePrintErrorMsg(err);
      }
      // console.log('class params:', this.class);
    },
    autoFillDate () {
      this.form.cls_date = new Date().toISOString().split('T')[0];
    },
    multiselectGroupAll (dataArr) {
      return [{
        label: this.$i18n.t('app.selectAll'),
        sub: dataArr,
      }];
    },
    inputCapture (evt) {
      console.log('input', evt);
    },
    handleSuccessRes (response) {
      this.showSuccessMsg(response);
      this.routeGoBackCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
#class-add {
  @include sub-page;

  .form {
    @include forms;
  }
}
</style>
